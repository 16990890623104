import {
  CardRank,
  CardSuit,
  SpecialCardValue,
  RegularCardValue,
  CardValue,
  HiddenCard,
  VisibleCard,
  RawCardValue,
  TotalNumberOfRegularCardValues,
  NumberOfRanks,
} from '../types/Card';
import { canonicalModulus, createRange, shuffle } from './general';

// ===== Creators ===== //
export function createHiddenCard(): HiddenCard {
  return {
    isVisible: false,
  };
}

export function createVisibleCard(value: CardValue): VisibleCard {
  return {
    isVisible: true,
    value,
  };
}

export function createSpecialCardValue(id: number): SpecialCardValue {
  return {
    id,
    isSpecial: true,
  };
}

export function createRegularCardValue(
  id: number,
  rank: CardRank,
  suit: CardSuit
): RegularCardValue {
  return {
    id,
    isSpecial: false,
    rank,
    suit,
  };
}

export function createRawDeck(jokerCount: number) {
  return shuffle(createRange(0, TotalNumberOfRegularCardValues + jokerCount));
}

export function createRawJokerOnlyDeck(jokerCount: number) {
  return shuffle(
    createRange(
      TotalNumberOfRegularCardValues,
      TotalNumberOfRegularCardValues + jokerCount
    )
  );
}

// ===== Rules ===== //
export function isCardInHand(card: CardValue, hand: CardValue[]) {
  return !!hand.find((handCard) => handCard.id === card.id);
}

export function isCardPlayable(proposedCard: CardValue, topCard: CardValue) {
  return (
    areCardsSameSuit(proposedCard, topCard) ||
    areCardsSameRank(proposedCard, topCard) ||
    // King or ace can be played on anything at the start of the turn
    isCardOfRank(proposedCard, CardRank.King) ||
    isCardOfRank(proposedCard, CardRank.Ace)
  );
}

export function isCardPlayableInRun(
  proposedCard: CardValue,
  topCard: CardValue
) {
  return (
    areCardsSameRank(proposedCard, topCard) ||
    (areCardsConsecutiveRank(proposedCard, topCard) &&
      areCardsSameSuit(proposedCard, topCard))
  );
}

export function isCardOfRank(card: CardValue, rank: CardRank) {
  if (card.isSpecial) return false;
  return card.rank === rank;
}

export function isCardOfSuit(card: CardValue, suit: CardSuit) {
  if (card.isSpecial) return false;
  return card.suit === suit;
}

export function areCardsSameSuit(card1: CardValue, card2: CardValue) {
  if (card1.isSpecial || card2.isSpecial) {
    return false;
  } else {
    return card1.suit === card2.suit;
  }
}

export function areCardsSameRank(card1: CardValue, card2: CardValue) {
  if (card1.isSpecial || card2.isSpecial) {
    return false;
  } else {
    return card1.rank === card2.rank;
  }
}

export function areCardsConsecutiveRank(card1: CardValue, card2: CardValue) {
  const value1 = getRankValue(card1);
  const value2 = getRankValue(card2);
  return (
    value2 === canonicalModulus(value1 + 1, 13) ||
    value2 === canonicalModulus(value1 - 1, 13)
  );
}

export function getCardValueFromRaw(rawCardValue: RawCardValue) {
  if (rawCardValue > TotalNumberOfRegularCardValues - 1) {
    return createSpecialCardValue(rawCardValue);
  }
  const rankValue = rawCardValue % NumberOfRanks;
  const suitValue = Math.floor(rawCardValue / NumberOfRanks);
  return createRegularCardValue(rawCardValue, rankValue, suitValue);
}

export function getRawCardValue(cardValue: CardValue) {
  if (cardValue.isSpecial) return TotalNumberOfRegularCardValues;
  return cardValue.suit * NumberOfRanks + cardValue.rank;
}

export function getSuitValue(card: CardValue) {
  if (card.isSpecial) {
    return -2; // Set to an unreachable number
  }
  switch (card.suit) {
    case CardSuit.Clubs:
      return 0;
    case CardSuit.Diamonds:
      return 1;
    case CardSuit.Hearts:
      return 2;
    case CardSuit.Spades:
      return 3;
  }
}

export function getRankValue(card: CardValue) {
  if (card.isSpecial) {
    return -2; // Set to an unreachable number
  }
  switch (card.rank) {
    case CardRank.Two:
      return 0;
    case CardRank.Three:
      return 1;
    case CardRank.Four:
      return 2;
    case CardRank.Five:
      return 3;
    case CardRank.Six:
      return 4;
    case CardRank.Seven:
      return 5;
    case CardRank.Eight:
      return 6;
    case CardRank.Nine:
      return 7;
    case CardRank.Ten:
      return 8;
    case CardRank.Jack:
      return 9;
    case CardRank.Queen:
      return 10;
    case CardRank.King:
      return 11;
    case CardRank.Ace:
      return 12;
  }
}

export function getRankSymbol(card: CardValue) {
  if (card.isSpecial) {
    return '★';
  }
  switch (card.rank) {
    case CardRank.Two:
      return '2';
    case CardRank.Three:
      return '3';
    case CardRank.Four:
      return '4';
    case CardRank.Five:
      return '5';
    case CardRank.Six:
      return '6';
    case CardRank.Seven:
      return '7';
    case CardRank.Eight:
      return '8';
    case CardRank.Nine:
      return '9';
    case CardRank.Ten:
      return '10';
    case CardRank.Jack:
      return 'J';
    case CardRank.Queen:
      return 'Q';
    case CardRank.King:
      return 'K';
    case CardRank.Ace:
      return 'A';
  }
}

export function getSuitSymbol(card: CardValue) {
  if (card.isSpecial) {
    return '';
  }
  switch (card.suit) {
    case CardSuit.Hearts:
      return '♥';
    case CardSuit.Diamonds:
      return '♦';
    case CardSuit.Clubs:
      return '♣';
    case CardSuit.Spades:
      return '♠';
  }
}

export function getSuitName(card: CardValue) {
  if (card.isSpecial) {
    return '';
  }
  switch (card.suit) {
    case CardSuit.Hearts:
      return 'Hearts';
    case CardSuit.Diamonds:
      return 'Diamonds';
    case CardSuit.Clubs:
      return 'Clubs';
    case CardSuit.Spades:
      return 'Spades';
  }
}

// ===== Sorting ===== //
function getCardSortRank(card: CardValue) {
  if (card.isSpecial) return -2;
  return getSuitValue(card) + getRankValue(card) * 4;
}

export function sortCards(cards: CardValue[]) {
  const cardsCopy = cards.slice();
  cardsCopy.sort((a, b) => getCardSortRank(a) - getCardSortRank(b));
  return cardsCopy;
}
