import { useCallback, useRef, useState } from 'react';

export function useCountdown() {
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState<
    number | null
  >(null);
  const intervalIdRef = useRef<number | null>(null);
  const countdownCount = useRef<number | null>(null);
  const cancelCountdown = useCallback(() => {
    if (intervalIdRef.current !== null) {
      window.clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    countdownCount.current = null;
    setRemainingTimeInSeconds(null);
  }, []);
  const startCountdown = useCallback(
    (timeInSeconds: number, callback?: () => void) => {
      if (intervalIdRef.current !== null) {
        window.clearInterval(intervalIdRef.current);
      }
      countdownCount.current = timeInSeconds;
      setRemainingTimeInSeconds(countdownCount.current);
      intervalIdRef.current = window.setInterval(() => {
        if (countdownCount.current === null) {
          cancelCountdown();
          return;
        }
        if (countdownCount.current <= 0) {
          countdownCount.current = null;
          setRemainingTimeInSeconds(0);
          if (callback) {
            callback();
          }
          return;
        }
        countdownCount.current -= 1;
        setRemainingTimeInSeconds(countdownCount.current);
      }, 1000);
    },
    [setRemainingTimeInSeconds, cancelCountdown]
  );
  return [remainingTimeInSeconds, startCountdown, cancelCountdown] as const;
}
