import { useCallback, useRef, useState } from 'react';

export function useTimeoutState<T>(defaultValue: T, timeoutInMs: number) {
  const [value, setInternalValue] = useState<T>(defaultValue);
  const timeoutIdRef = useRef<number | null>(null);
  const setValue = useCallback(
    (newValue) => {
      setInternalValue(newValue);
      if (timeoutIdRef.current !== null) {
        window.clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = window.setTimeout(() => {
        setInternalValue(defaultValue);
        timeoutIdRef.current = null;
      }, timeoutInMs);
    },
    [defaultValue, timeoutInMs, setInternalValue]
  );
  return [value, setValue] as const;
}

export function useDebouncedCallback<
  Arguments extends any[],
  Return extends any
>(
  fn: (...args: Arguments) => Return,
  timeoutInMs: number
): (...args: Arguments) => Return | undefined {
  const timeoutIdRef = useRef<number | undefined>(undefined);
  return useCallback(
    (...args) => {
      if (timeoutIdRef.current !== undefined) {
        return undefined;
      }
      timeoutIdRef.current = window.setTimeout(() => {
        timeoutIdRef.current = undefined;
      }, timeoutInMs);
      return fn(...args);
    },
    [fn]
  );
}
