import React from 'react';
import './PlayingCardFace.css';
import classNames from 'classnames';

export interface PlayingCardFaceProps {
  children?: React.ReactNode;
}

function PlayingCardFace({ children }: PlayingCardFaceProps) {
  return (
    <div
      className={classNames('playingCardFace', {
        'playingCardFace--isCardBack': !children,
      })}
    >
      {children}
    </div>
  );
}

export default PlayingCardFace;
