import React, { ForwardedRef } from 'react';
import './VisiblePlayingCard.css';
import { CardValue } from '../../types/Card';
import PlayingCardLabel from '../PlayingCardLabel/PlayingCardLabel';
import PlayingCard from '../PlayingCard/PlayingCard';
import PlayingCardFace from '../PlayingCardFace/PlayingCardFace';
import { noop } from '../../lib/general';

export interface VisiblePlayingCardProps {
  onSelectCard?: (cardValue: CardValue) => void;
  value: CardValue;
}

function VisiblePlayingCard(
  { value, onSelectCard = noop }: VisiblePlayingCardProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  return (
    <PlayingCard
      onSelect={() => onSelectCard(value)}
      frontface={
        <PlayingCardFace>
          <div className="visiblePlayingCard__label visiblePlayingCard__label--top">
            <PlayingCardLabel value={value} />
          </div>
          <div className="visiblePlayingCard__label visiblePlayingCard__label--center">
            <PlayingCardLabel value={value} />
          </div>
          <div className="visiblePlayingCard__label visiblePlayingCard__label--bottom">
            <PlayingCardLabel value={value} />
          </div>
        </PlayingCardFace>
      }
      backface={<PlayingCardFace />}
      ref={forwardedRef}
    />
  );
}

export default React.forwardRef(VisiblePlayingCard);
