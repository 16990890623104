export type Card = HiddenCard | VisibleCard;

export type HiddenCard = {
  isVisible: false;
};

export type VisibleCard = {
  isVisible: true;
  value: CardValue;
};

export type CardValue = SpecialCardValue | RegularCardValue;

export type SpecialCardValue = {
  id: number;
  isSpecial: true;
};

export type RegularCardValue = {
  id: number;
  isSpecial: false;
  suit: CardSuit;
  rank: CardRank;
};

export enum CardSuit {
  Hearts,
  Diamonds,
  Clubs,
  Spades,
}

export enum CardRank {
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Jack,
  Queen,
  King,
  Ace,
}

export type RawCardValue = number; // 0 - 51 for regular cards, 52+ for special cards

export const NumberOfSuits = 4;

export const NumberOfRanks = 13;

export const TotalNumberOfRegularCardValues = NumberOfSuits * NumberOfRanks;
