import { Room } from '../types/Room';
import { callApi } from './core';

export async function getActiveRooms() {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms`,
    method: 'GET',
  });
  return rooms;
}

export async function getRoom(id: string) {
  const {
    rooms: [room],
  } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms/${id}`,
    method: 'GET',
  });
  return room;
}

export async function createRoom({
  name,
  isPrivate,
}: {
  name: string;
  isPrivate: boolean;
}) {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms`,
    method: 'POST',
    requestBody: {
      name,
      isPrivate,
    },
  });
  return rooms;
}

export async function updateRoom(
  id: string,
  {
    name,
    hasTeams,
    canPlaySneakTurns,
    jokerCount,
    startingHandCount,
  }: {
    name?: string;
    hasTeams?: boolean;
    canPlaySneakTurns?: boolean;
    jokerCount?: number;
    startingHandCount?: number;
  }
) {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms/${id}`,
    method: 'PATCH',
    requestBody: {
      name,
      hasTeams,
      canPlaySneakTurns,
      jokerCount,
      startingHandCount,
    },
  });
  return rooms;
}
