import { Dict } from '../types/General';

// ===== Arrays ===== //
export function peek<Item>(entities: Item[]) {
  if (entities.length === 0) {
    return undefined;
  }
  return entities[entities.length - 1];
}

export function getRandom<Item>(array: Item[]) {
  return array[Math.floor(Math.random() * array.length)];
}

export function getCount<Item>(array: Item[]) {
  return array.length;
}

// Creates a range of integers from `start` (inclusive) to `end` (exclusive)
export function createRange(start: number, end: number) {
  const range = [];
  for (let i = start; i < end; i++) {
    range.push(i);
  }
  return range;
}

export function shuffle<Item>(array: Item[], targetArray: Item[] = []): Item[] {
  if (array.length === 0) {
    return targetArray;
  }
  const [item, ...rest] = array;
  const insertIndex = Math.floor(Math.random() * (targetArray.length + 1));
  const newTargetArray = [
    ...targetArray.slice(0, insertIndex),
    item,
    ...targetArray.slice(insertIndex, targetArray.length),
  ];
  return shuffle(rest, newTargetArray);
}

export function selectWithId<IdType, Entity extends { id: IdType }>(
  entities: Entity[],
  excludedId: IdType
) {
  return peek(entities.filter((entity) => entity.id === excludedId));
}

export function excludeWithId<IdType, Entity extends { id: IdType }>(
  entities: Entity[],
  excludedId: IdType
) {
  return entities.filter((entity) => entity.id !== excludedId);
}

export function flattenRecords<Obj extends {}, Values extends Obj[keyof Obj]>(
  object: Obj
): Values[] {
  return Object.values(object);
}

export function areAll<Item>(
  array: Item[],
  condition: (item: Item) => boolean
) {
  return array.reduce((result, item) => result && condition(item), true);
}

export function areAny<Item>(
  array: Item[],
  condition: (item: Item) => boolean
) {
  return array.reduce((result, item) => result || condition(item), false);
}

export function batch<Item>(array: Item[], batchSize: number) {
  return array.reduce((result, item, index) => {
    const batchIndex = Math.floor(index / batchSize);
    result[batchIndex] = result[batchIndex] || [];
    result[batchIndex].push(item);
    return result;
  }, [] as Item[][]);
}

export function rotateToStartWith<Item>(array: Item[], targetStart: Item) {
  const indexOfTarget = array.indexOf(targetStart);
  if (indexOfTarget === -1) {
    return array;
  }
  return array
    .slice(indexOfTarget, array.length)
    .concat(array.slice(0, indexOfTarget));
}

export function optionalToArray<T>(value: T | undefined | null) {
  return value !== undefined && value !== null ? [value] : [];
}

// ===== Ids ===== //
export function createUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      // eslint-disable-next-line
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// ===== Objects ===== //
export function excludeKey<Values>(
  object: Dict<Values>,
  excludedKey: string
): Dict<Values> {
  const { [excludedKey]: excludedValue, ...rest } = object;
  return rest;
}

export function mapValues<Obj, Values extends Obj[keyof Obj], TargetType>(
  object: Obj,
  mapFunction: (value: Values, key: string) => TargetType
): Dict<TargetType> {
  const mappedEntries = Object.entries(object).map(([key, value]) => [
    key,
    mapFunction(value, key),
  ]);
  return Object.fromEntries(mappedEntries);
}

export function filterValues<Obj, Values extends Obj[keyof Obj], TargetType>(
  object: Obj,
  filterFunction: (value: Values, key: string) => boolean
): Dict<TargetType> {
  const mappedEntries = Object.entries(object).filter(([key, value]) =>
    filterFunction(value, key)
  );
  return Object.fromEntries(mappedEntries);
}

export function createDict<
  Value extends { [key in Key]: string },
  Key extends keyof Value
>(values: Value[], key: Key) {
  return values.reduce(
    (result, value) => ({
      ...result,
      [value[key]]: value,
    }),
    {} as Dict<Value>
  );
}

export function optionalToObject<Key extends string, Value>(
  key: Key,
  value: Value | undefined | null
): Record<Key, Value> | {} {
  return value !== undefined && value !== null ? { [key]: value } : {};
}

// ===== Functions ===== //
export function noop() {}

// ===== Booleans ===== //
export function xor(value1: boolean, value2: boolean) {
  return (value1 || value2) && !(value1 && value2);
}

// ===== Other ===== //
export function isDefined<T>(maybeValue: T | undefined): maybeValue is T {
  return maybeValue !== undefined;
}

export function isNotNull<T>(maybeValue: T | null): maybeValue is T {
  return maybeValue !== null;
}

export function canonicalModulus(value: number, maximumValue: number) {
  const modulusResult = value % maximumValue;
  return modulusResult < 0 ? modulusResult + maximumValue : modulusResult;
}

export function forCount(count: number, callback: (index: number) => void) {
  for (let index = 0; index < count; index++) {
    callback(index);
  }
}

export function mapCount<ReturnType>(
  count: number,
  callback: (index: number) => ReturnType
) {
  return Array.from({ length: count }, (value, index) => callback(index));
}
