import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useOwnUserId } from '../../hooks/store/authentication';
import { useCreateRoom } from '../../hooks/store/room';
import { createDraftRoom } from '../../lib/room';
import { useHash } from '../../lib/routing';
import CheckBox from '../Forms/CheckBox';
import EditableText from '../Forms/EditableText';
import { Modal } from '../Modal/Modal';

export default function CreateRoomModal() {
  const navigate = useNavigate();
  const createRoom = useCreateRoom();
  const [draftRoom, setDraftRoom] = useState(createDraftRoom());
  const hash = useHash();
  const ownUserId = useOwnUserId();
  const handleChangeName = (name: string) => {
    setDraftRoom((prevDraftRoom) => ({
      ...prevDraftRoom,
      name,
    }));
  };
  const handleChangeIsPrivate = (isPrivate: boolean) => {
    setDraftRoom((prevDraftRoom) => ({
      ...prevDraftRoom,
      isPrivate,
    }));
  };
  const handleCreateRoom = async () => {
    if (!ownUserId) return;
    // Post new room to server
    const room = await createRoom(draftRoom.name, draftRoom.isPrivate);
    // Use the id to navigate to the room
    navigate(`/room/${room.id}`);
  };
  const handleClose = useCallback(() => {
    navigate('#');
  }, [navigate]);
  if (hash !== '#create-room') return null;
  return (
    <Modal onClose={handleClose}>
      <h2>Create New Room</h2>
      <p>Create a new room to invite people to and begin playing</p>
      <h3>Options</h3>
      <p>
        Name:{' '}
        <EditableText value={draftRoom.name} onChange={handleChangeName} />
      </p>
      <p>
        Private:{' '}
        <CheckBox
          value={draftRoom.isPrivate}
          onChange={handleChangeIsPrivate}
        />
      </p>
      <button onClick={handleCreateRoom}>Create Room</button>
      <p>
        <Link to="#">Close</Link>
      </p>
    </Modal>
  );
}
