import { User } from '../types/User';
import { callApi } from './core';

export async function authenticate() {
  const { users } = await callApi<{ users: User[] }>({
    endpoint: '/authentication/authenticate',
    method: 'POST',
  });
  return users[0];
}
