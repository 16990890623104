export class ExponentialTimer {
  private defaultTimeoutInMs: number;
  private exponentialFactor: number;
  private timeoutId: number | null = null;
  private nextTimeoutInMs: number;

  constructor(options: {
    defaultTimeoutInMs: number;
    exponentialFactor: number;
  }) {
    this.defaultTimeoutInMs = options.defaultTimeoutInMs;
    this.nextTimeoutInMs = options.defaultTimeoutInMs;
    this.exponentialFactor = options.exponentialFactor;
  }

  clearTimeout() {
    if (this.timeoutId !== null) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.nextTimeoutInMs = this.defaultTimeoutInMs;
  }

  setTimeout(callback: () => void) {
    if (this.timeoutId !== null) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.timeoutId = window.setTimeout(() => {
      callback();
    }, this.nextTimeoutInMs);
    this.nextTimeoutInMs *= this.exponentialFactor;
  }
}
