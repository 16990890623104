import React, {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import className from 'classnames';
import './PlayerHand.css';
import { Card, CardValue, VisibleCard } from '../../types/Card';
import VisiblePlayingCard from '../VisiblePlayingCard/VisiblePlayingCard';
import HiddenPlayingCard from '../HiddenPlayingCard/HiddenPlayingCard';
import { noop } from '../../lib/general';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { useAnimateCards } from '../../hooks/useAnimateCards';
import { createVisibleCard } from '../../lib/card';
import {
  CARD_ENTER_DURATION,
  CARD_EXIT_DURATION,
} from '../PlayingCard/playingCardConstants';

export enum CollapseLevel {
  Expanded,
  Collapsed,
  Condensed,
}

interface PlayerHandProps {
  cards: Card[];
  collapseLevel: CollapseLevel;
  onSelectCard?: (cardValue: CardValue) => void;
  isOwnUser: boolean;
  overrideCardValueRef?: RefObject<CardValue>;
}

function PlayerHand({
  cards,
  collapseLevel,
  onSelectCard = noop,
  isOwnUser,
  overrideCardValueRef,
}: PlayerHandProps) {
  // TODO: Verify if this is needed or not
  const [transitionExtCounter, setTransitionExtCounter] = useState(0);
  const isEmptyHanded = cards.length === 0;
  return (
    <TransitionGroup
      className={className('playerHand', {
        'playerHand--expanded':
          !isEmptyHanded && collapseLevel === CollapseLevel.Expanded,
        'playerHand--collapsed':
          !isEmptyHanded && collapseLevel === CollapseLevel.Collapsed,
        'playerHand--condensed':
          !isEmptyHanded && collapseLevel === CollapseLevel.Condensed,
      })}
    >
      {cards.map((card, index) => (
        <PlayerCardWithRef
          // Using the index here because we don't mind which hidden card is removed
          key={isOwnUser && card.isVisible ? card.value.id : index}
          card={card}
          overrideCardValueRef={overrideCardValueRef}
          collapseLevel={collapseLevel}
          onSelectCard={onSelectCard}
          onTransitionExit={() => setTransitionExtCounter((count) => count + 1)}
          totalCardCount={cards.length}
          transitionExtCounter={transitionExtCounter}
          isOwnUser={isOwnUser}
        />
      ))}
    </TransitionGroup>
  );
}

interface PlayerCardProps {
  key: string | number;
  card: Card;
  overrideCardValueRef?: RefObject<CardValue>;
  collapseLevel: CollapseLevel;
  onSelectCard: (cardValue: CardValue) => void;
  onTransitionExit: () => void;
  totalCardCount: number;
  transitionExtCounter: number;
  isOwnUser: boolean;
}

function PlayerCard(
  {
    key,
    card,
    overrideCardValueRef,
    collapseLevel,
    onSelectCard,
    totalCardCount,
    transitionExtCounter,
    onTransitionExit,
    isOwnUser,
    ...transitionProps
  }: PlayerCardProps & Partial<CSSTransitionProps>,
  forwardedRef: ForwardedRef<MutableRefObject<HTMLDivElement | null>>
) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [shownCard, setShownCard] = useState(card);

  // Forward the ref
  useImperativeHandle(forwardedRef, () => elementRef);

  useAnimateCards({
    totalCardCount,
    transitionProps,
    transitionExtCounter,
    onTransitionExit,
    elementRef,
    isOwnUser,
  });

  useEffect(() => {
    // Set the played card to the to discard card if this is an opponent
    if (!transitionProps.in && !isOwnUser && overrideCardValueRef?.current) {
      console.log('triggered', card);
      setShownCard(createVisibleCard(overrideCardValueRef?.current));
    }
  }, [transitionProps.in]);

  return (
    <CSSTransition
      key={key}
      timeout={{
        enter: CARD_ENTER_DURATION,
        exit: CARD_EXIT_DURATION,
      }}
      classNames="playerHand__card--group"
      nodeRef={elementRef}
      {...transitionProps}
    >
      <div
        ref={elementRef}
        className={className('playerHand__card', {
          'playerHand__card--expanded':
            collapseLevel === CollapseLevel.Expanded,
          'playerHand__card--collapsed':
            collapseLevel === CollapseLevel.Collapsed,
          'playerHand__card--condensed':
            collapseLevel === CollapseLevel.Condensed,
          'playerHand__card--leaving': !transitionProps.in,
          'playerHand__card--isOtherUsers': !isOwnUser,
        })}
      >
        {shownCard.isVisible ? (
          <VisiblePlayingCard
            value={shownCard.value}
            onSelectCard={onSelectCard}
          />
        ) : (
          <HiddenPlayingCard />
        )}
      </div>
    </CSSTransition>
  );
}

const PlayerCardWithRef = React.forwardRef(PlayerCard);

export default PlayerHand;
