import { PlayerGamePartial } from '../types/Game';
import { Room } from '../types/Room';
import { callApi } from './core';

export async function getGame(roomId: string, gameId: string) {
  const {
    games: [game],
  } = await callApi<{ games: PlayerGamePartial[] }>({
    endpoint: `/rooms/${roomId}/games/${gameId}`,
    method: 'GET',
  });
  return game;
}

export async function createGame(roomId: string) {
  const {
    games: [game],
    rooms: [room],
  } = await callApi<{ games: PlayerGamePartial[]; rooms: Room[] }>({
    endpoint: `/rooms/${roomId}/games`,
    method: 'POST',
  });
  return { game, room };
}
