import React from 'react';
import './PlayingCardLabel.css';
import { CardValue } from '../../types/Card';
import { getSuitSymbol, getRankSymbol } from '../../lib/card';
import classNames from 'classnames';

export interface PlayingCardLabelProps {
  value: CardValue;
}

function PlayingCardLabel({ value }: PlayingCardLabelProps) {
  const isRed = !value.isSpecial && (value.suit === 0 || value.suit === 1);
  return (
    <>
      <div
        className={classNames('cardLabel__rank', { 'cardLabel--red': isRed })}
      >
        {getRankSymbol(value)}
      </div>
      <div
        className={classNames('cardLabel__suit', { 'cardLabel--red': isRed })}
      >
        {getSuitSymbol(value)}
      </div>
    </>
  );
}

export default PlayingCardLabel;
