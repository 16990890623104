import React, { ForwardedRef, MutableRefObject } from 'react';
import { noop } from '../../lib/general';
import './PlayingCard.css';

export interface PlayingCardProps {
  frontface: React.ReactNode;
  backface: React.ReactNode;
  onSelect?: () => void;
}

function PlayingCard(
  { frontface, backface, onSelect = noop }: PlayingCardProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  return (
    <div className="playingCard" onClick={onSelect} ref={forwardedRef}>
      <div className="playingCard__face playingCard__face--frontface">
        {frontface}
      </div>
      <div className="playingCard__face playingCard__face--backface">
        {backface}
      </div>
    </div>
  );
}

export default React.forwardRef(PlayingCard);
