import { User } from '../types/User';
import { callApi } from './core';

export async function updateUser(userId: string, newName: string) {
  const {
    users: [user],
  } = await callApi<{ users: User[] }>({
    endpoint: `/users/${userId}`,
    method: 'PUT',
    requestBody: {
      name: newName,
    },
  });
  return user;
}

export async function getUsers(userIds: string[]) {
  const { users } = await callApi<{ users: User[] }>({
    endpoint: `/users`,
    method: 'GET',
    queryParams: {
      ids: userIds.join(','),
    },
  });
  return users;
}
