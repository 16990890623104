import {
  createTeam,
  removeTeam,
  updateTeamName,
  updateTeamUserIds,
} from '../../api-client/team';
import { useRoomStore } from './room';

export function useCreateTeam() {
  const { setRoom } = useRoomStore();
  return async (roomId: string) => {
    const [room] = await createTeam({ roomId });
    setRoom(room.id, () => room);
    return room;
  };
}

export function useRemoveTeam() {
  const { setRoom } = useRoomStore();
  return async (roomId: string, teamId: string) => {
    const [room] = await removeTeam({ roomId, teamId });
    setRoom(room.id, () => room);
    return room;
  };
}

export function useUpdateTeamName() {
  const { setRoom } = useRoomStore();
  return async (
    roomId: string,
    teamId: string,
    updateSettings: { name: string },
    options?: { shouldKeepAlive?: boolean }
  ) => {
    const [room] = await updateTeamName(
      { roomId, teamId, updateSettings },
      options
    );
    setRoom(room.id, () => room);
    return room;
  };
}

export function useUpdateTeamUserIds() {
  const { setRoom } = useRoomStore();
  return async (
    roomId: string,
    teamId: string,
    userIdIndex: 0 | 1,
    updateSettings: { userId: string | null },
    options?: { shouldKeepAlive?: boolean }
  ) => {
    const [room] = await updateTeamUserIds(
      { roomId, teamId, userIdIndex, updateSettings },
      options
    );
    setRoom(room.id, () => room);
    return room;
  };
}
