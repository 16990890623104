import { Dict } from './General';
import { Team } from './Team';

export interface Room {
  id: string;
  createdOn: string;
  status: RoomStatus;
  activeGameId: string | null;
  name: string;
  hostUserId: string;
  teams: Dict<Team>;
  teamOrder: string[]; // Team ids
  // gameIds: string[]; // Ids of the games played/playing in order
  scores: Dict<number>; // Wins by userId
  hasTeams: boolean; // Enforces teams of 2 instead of 1
  canPlaySneakTurns: boolean; // Rules like speed survival
  jokerCount: number;
  startingHandCount: number;
  isPrivate: boolean; // This hides the room from being listed in the home page
}

export interface DraftRoom {
  name: string;
  isPrivate: boolean; // This hides the room from being listed in the home page
}

export enum RoomStatus {
  Waiting = 'waiting', // Room is waiting for new players
  Playing = 'playing', // Room is currently in a game
  Inctive = 'inactive', // Room has been closed
}

export function castRoomStatus(statusString: string) {
  switch (statusString) {
    case 'waiting':
      return RoomStatus.Waiting;
    case 'playing':
      return RoomStatus.Playing;
    case 'inactive':
      return RoomStatus.Inctive;
    default:
      console.warn(`Unidentified room status used: ${statusString}`);
      return RoomStatus.Waiting;
  }
}

export function isValidRoomName(proposedName: string) {
  return (
    typeof proposedName === 'string' &&
    proposedName.length >= MinRoomNameLength &&
    proposedName.length <= MaxRoomNameLength
  );
}

export const DefaultRoomName = 'New Room';
export const MinRoomNameLength = 1;
export const MaxRoomNameLength = 100;

export const GameMinPlayers = 2;
export const GameMaxPlayers = 6;

export const MinJokerCount = 0;
export const MaxJokerCount = 10;
export const DefaultJokerCount = 2;

export const MinStartingHandCount = 3;
export const MaxStartingHandCount = 8;
export const DefaultStartingHandCount = 5;

export const MinTeamCount = 1;
export const MaxTeamCount = 3;
