import React from 'react';
import {
  useDebouncedCallback,
  useTimeoutState,
} from '../../hooks/useTimeoutState';
import { PlayerGamePartial } from '../../types/Game';
import HiddenPlayingCard from '../HiddenPlayingCard/HiddenPlayingCard';
import { CARD_ENTER_DURATION } from '../PlayingCard/playingCardConstants';
import PlayingCardPlaceholder from '../PlayingCardPlaceholder/PlayingCardPlaceholder';

interface DeckProps {
  game: PlayerGamePartial;
  isOwnUsersTurn: boolean;
  roomId: string;
  gameId: string;
  onClick: (roomId: string, gameId: string) => void;
}

export function Deck({
  game,
  isOwnUsersTurn,
  roomId,
  gameId,
  onClick,
}: DeckProps) {
  const debouncedOnClick = useDebouncedCallback(onClick, CARD_ENTER_DURATION);
  if (game.deckLength === 0) return <PlayingCardPlaceholder />;
  return (
    <HiddenPlayingCard
      onSelectCard={() => isOwnUsersTurn && debouncedOnClick(roomId, gameId)}
    />
  );
}
