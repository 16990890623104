import React from 'react';
import './CounterInput.css';

interface CounterInputProps {
  value: number;
  maxValue: number;
  minValue: number;
  onChange: (newValue: number) => void;
}

export default function CounterInput({
  value,
  onChange,
  maxValue,
  minValue,
}: CounterInputProps) {
  const isValid = (value: number) => {
    return value <= maxValue && value >= minValue;
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validateAndPropogateChange(+event.target.value);
  };
  const decrement = () => {
    validateAndPropogateChange(value - 1);
  };
  const increment = () => {
    validateAndPropogateChange(value + 1);
  };
  const validateAndPropogateChange = (newValue: number) => {
    if (!isValid(newValue)) {
      return;
    }
    onChange(newValue);
  };
  return (
    <span>
      <button onClick={decrement} disabled={!isValid(value - 1)}>
        -
      </button>
      <input
        className="counterInput"
        type="number"
        value={value}
        onChange={handleChange}
      />
      <button onClick={increment} disabled={!isValid(value + 1)}>
        +
      </button>
    </span>
  );
}
