import React from 'react';
import './HiddenPlayingCard.css';
import PlayingCard from '../PlayingCard/PlayingCard';
import PlayingCardFace from '../PlayingCardFace/PlayingCardFace';
import { noop } from '../../lib/general';
import { CardValue } from '../../types/Card';

interface HiddenPlayingCardProps {
  onSelectCard?: (cardValue: CardValue | null) => void;
}

function HiddenPlayingCard({ onSelectCard = noop }: HiddenPlayingCardProps) {
  return (
    <PlayingCard
      onSelect={() => onSelectCard(null)}
      frontface={<PlayingCardFace />}
      backface={<PlayingCardFace />}
    />
  );
}

export default HiddenPlayingCard;
