import classNames from 'classnames';
import React from 'react';
import { PlayerStatus } from '../../types/Player';
import './PlayerArea.css';

export interface PlayerAreaProps {
  name: string;
  children?: React.ReactNode;
  isThisUsersTurn: boolean;
  status: PlayerStatus;
}

function PlayerArea({
  name,
  children,
  isThisUsersTurn,
  status,
}: PlayerAreaProps) {
  return (
    <div className="playerArea">
      <div className="playerArea__nameContainer">
        <span
          className={classNames('playerArea__name', {
            'playerArea__name--highlighted': isThisUsersTurn,
          })}
        >
          {name}
        </span>
      </div>
      <div className="playerArea__cardContainer">
        <div
          className={classNames({
            'playerArea__cards--disabled': status !== PlayerStatus.Active,
          })}
        >
          {children}
        </div>
        <PlayerStatusIndicator status={status} />
      </div>
    </div>
  );
}

function PlayerStatusIndicator({ status }: { status: PlayerStatus }) {
  switch (status) {
    case PlayerStatus.Won:
      return (
        <div className="playerStatusIndicator playerStatusIndicator--won">
          Winner!
        </div>
      );
    case PlayerStatus.Lost:
      return (
        <div className="playerStatusIndicator playerStatusIndicator--lost">
          Loser
        </div>
      );
    case PlayerStatus.Eliminated:
      return (
        <div className="playerStatusIndicator playerStatusIndicator--eliminated">
          Eliminated
        </div>
      );
    case PlayerStatus.Quit:
      return (
        <div className="playerStatusIndicator playerStatusIndicator--quit">
          Quit
        </div>
      );
    default:
      return null;
  }
}

export default PlayerArea;
