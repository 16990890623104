import { CardValue } from './Card';

// The full player state as seen only on the server
export interface Player {
  id: string;
  createdOn: string;
  userId: string;
  hand: CardValue[];
  status: PlayerStatus;
}

type PlayerPrivateKeys = 'hand';

// The portion of the player state visible to other players
export type PlayerSummary = Omit<Player, PlayerPrivateKeys> & {
  handCount: number;
};

// The portion of the player state visible to the owning player
export type OwnPlayer = Player;

export enum PlayerStatus {
  Active = 'active', // Player is still playing the game
  Quit = 'quit', // Player left the game
  Eliminated = 'emliminated', // Eliminated via joker
  Lost = 'lost', // Lost by another player winning
  Won = 'won', // Won by having no cards, or being the only one left
}

export function castPlayerStatus(statusString: string) {
  switch (statusString) {
    case 'active':
      return PlayerStatus.Active;
    case 'quit':
      return PlayerStatus.Quit;
    case 'emliminated':
      return PlayerStatus.Eliminated;
    case 'lost':
      return PlayerStatus.Lost;
    case 'won':
      return PlayerStatus.Won;
    default:
      console.warn(`Unidentified player status used: ${statusString}`);
      return PlayerStatus.Active;
  }
}
