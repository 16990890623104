import { optionalToObject } from '../lib/general';
import { PlayerGamePartial } from '../types/Game';
import { GameAction } from '../types/GameActions';
import { Room } from '../types/Room';
import { callApi } from './core';

export async function sendGameAction(
  roomId: string,
  gameId: string,
  action: GameAction
): Promise<{ game: PlayerGamePartial; room?: Room }> {
  const {
    games: [game],
    rooms: [room],
  } = await callApi<{ games: PlayerGamePartial[]; rooms: Room[] }>({
    endpoint: `/rooms/${roomId}/games/${gameId}/actions`,
    method: 'POST',
    requestBody: {
      action,
    },
  });
  return { game, ...optionalToObject('room', room) };
}
