import React, { ForwardedRef } from 'react';
import './PlayingCardPlaceholder.css';

function PlayingCardPlaceholder(
  {},
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  return <div className="playingCardPlaceholder" ref={forwardedRef} />;
}

export default React.forwardRef(PlayingCardPlaceholder);
