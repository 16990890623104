import React, { createContext, useContext, useState } from 'react';
import { authenticate } from '../../api-client/auth';
import { useUserStore } from './user';

interface AuthenticationStore {
  ownUserId: string;
}

// Store
const AuthenticationStoreContext = createContext<AuthenticationStore | null>(
  null
);
const SetAuthenticationStoreContext = createContext<
  (newStore: AuthenticationStore) => void
>(() => {});

export const AuthenticationStoreProvider: React.FC = ({ children }) => {
  const [authenticationStore, setAuthenticationStore] =
    useState<AuthenticationStore | null>(null);
  return (
    <AuthenticationStoreContext.Provider value={authenticationStore}>
      <SetAuthenticationStoreContext.Provider value={setAuthenticationStore}>
        {children}
      </SetAuthenticationStoreContext.Provider>
    </AuthenticationStoreContext.Provider>
  );
};

export function useAuthenticationStore() {
  const authenticationStore = useContext(AuthenticationStoreContext);
  const setAuthenticationStore = useContext(SetAuthenticationStoreContext);
  const setAuthentication = (ownUserId: string) => {
    setAuthenticationStore({
      ownUserId,
    });
  };
  const getAuthentication = () => authenticationStore;
  return { getAuthentication, setAuthentication };
}

// Selectors
export function useOwnUserId() {
  const { getAuthentication } = useAuthenticationStore();
  return getAuthentication()?.ownUserId;
}

export function useOwnUser() {
  const { getUser } = useUserStore();
  const ownUserId = useOwnUserId();
  if (!ownUserId) return null;
  return getUser(ownUserId);
}

// Fetchers
export function useAuthenticate() {
  const { setAuthentication } = useAuthenticationStore();
  const { setUser } = useUserStore();
  return async () => {
    const user = await authenticate();
    setUser(user);
    setAuthentication(user.id);
  };
}
