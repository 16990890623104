import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Dict } from '../../types/General';
import { Room } from '../../types/Room';
import {
  canJoinTeam,
  canLeaveTeam,
  canRemoveTeam,
  canSetTeamName,
  getTeamIdForUser,
  getUserIdsWithNewMember,
  getUserIdsWithoutMember,
  isValidJokerCount,
  isValidStartingHandCount,
} from '../../lib/team';
import { Team } from '../../types/Team';
import { excludeKey } from '../../lib/general';
import {
  createRoom,
  getActiveRooms,
  getRoom,
  updateRoom,
} from '../../api-client/rooms';
import { subscribe } from '../../api-client/core';
import { useFetchUsers, useUserStore } from './user';
import { getAllUserIdsInRoom } from '../../lib/room';
import { useBoundRef } from '../useBoundRef';

// Store
const RoomsStoreContext = createContext<Dict<Room>>({});
const SetRoomsStoreContext = createContext<
  React.Dispatch<React.SetStateAction<Dict<Room>>>
>(() => {});

export const RoomsStoreProvider: React.FC = ({ children }) => {
  const [roomsStore, setRoomsStore] = useState<Dict<Room>>({});
  return (
    <RoomsStoreContext.Provider value={roomsStore}>
      <SetRoomsStoreContext.Provider value={setRoomsStore}>
        {children}
      </SetRoomsStoreContext.Provider>
    </RoomsStoreContext.Provider>
  );
};

export function useRoomStore() {
  const roomsStore = useContext(RoomsStoreContext);
  const setRoomsStore = useContext(SetRoomsStoreContext);
  const setRoom = (roomId: string, transformRoom: (prevRoom: Room) => Room) => {
    setRoomsStore((prevState) => {
      const newRoom = transformRoom(prevState[roomId]);
      return {
        ...prevState,
        [roomId]: newRoom,
      };
    });
  };
  const getRoom = (id: string) => roomsStore[id];
  return { roomsStore, getRoom, setRoom };
}

// Selectors
export function useRoom(id: string | undefined) {
  const { getRoom } = useRoomStore();
  if (!id) return null;
  return getRoom(id);
}

export function useRooms() {
  const { roomsStore } = useRoomStore();
  return Object.values(roomsStore); // TODO sorting
}

// Transformations
export function getRoomWithUserAddedToTeam(
  room: Room,
  teamId: string,
  userId: string
): Room {
  return {
    ...room,
    teams: {
      ...room.teams,
      [teamId]: {
        ...room.teams[teamId],
        userIds: getUserIdsWithNewMember(userId, room.teams[teamId]),
      },
    },
  };
}

export function getRoomWithUserRemovedFromTeam(
  room: Room,
  teamId: string,
  userId: string
): Room {
  return {
    ...room,
    teams: {
      ...room.teams,
      [teamId]: {
        ...room.teams[teamId],
        userIds: getUserIdsWithoutMember(userId, room.teams[teamId]),
      },
    },
  };
}

// Actions
export function useSetRoomName() {
  const { setRoom } = useRoomStore();
  return (id: string, name: string) =>
    setRoom(id, (prevRoom) => ({
      ...prevRoom,
      name,
    }));
}

export function useSetJokerCount() {
  const { setRoom } = useRoomStore();
  return (id: string, jokerCount: number) => {
    if (!isValidJokerCount(jokerCount)) return;
    setRoom(id, (prevRoom) => ({
      ...prevRoom,
      jokerCount,
    }));
  };
}

export function useSetStartingHandCount() {
  const { setRoom } = useRoomStore();
  return (id: string, startingHandCount: number) => {
    if (!isValidStartingHandCount(startingHandCount)) return;
    setRoom(id, (prevRoom) => ({
      ...prevRoom,
      startingHandCount,
    }));
  };
}

export function useSetCanPlaySneakTurns() {
  const { setRoom } = useRoomStore();
  return (id: string, canPlaySneakTurns: boolean) => {
    setRoom(id, (prevRoom) => ({
      ...prevRoom,
      canPlaySneakTurns,
    }));
  };
}

export function useSetHasTeams() {
  const { setRoom } = useRoomStore();
  return (id: string, hasTeams: boolean) => {
    setRoom(id, (prevRoom) => {
      if (hasTeams && !prevRoom.hasTeams) {
        // Handle changing to team structure
      }
      return {
        ...prevRoom,
        hasTeams,
      };
    });
  };
}

export function useSetTeamName() {
  const { setRoom } = useRoomStore();
  return (roomId: string, teamId: string, name: string, ownUserId: string) => {
    setRoom(roomId, (prevRoom) => {
      const team = prevRoom.teams[teamId];
      if (!canSetTeamName(team, ownUserId)) return prevRoom;
      return {
        ...prevRoom,
        teams: {
          ...prevRoom.teams,
          [teamId]: {
            ...team,
            name,
          },
        },
      };
    });
  };
}

export function useAddUserIdToTeam() {
  const { setRoom } = useRoomStore();
  return (roomId: string, teamId: string, userId: string) => {
    setRoom(roomId, (prevRoom) => {
      const team = prevRoom.teams[teamId];
      if (!canJoinTeam(userId, team)) return prevRoom;
      return getRoomWithUserAddedToTeam(prevRoom, teamId, userId);
    });
  };
}

export function useRemoveUserIdFromTeam() {
  const { setRoom } = useRoomStore();
  return (roomId: string, teamId: string, userId: string) => {
    setRoom(roomId, (prevRoom) => {
      const team = prevRoom.teams[teamId];
      if (!canLeaveTeam(userId, team)) return prevRoom;
      return getRoomWithUserRemovedFromTeam(prevRoom, teamId, userId);
    });
  };
}

export function useSwitchTeams() {
  const { setRoom } = useRoomStore();
  return (roomId: string, newTeamId: string, userId: string) => {
    setRoom(roomId, (prevRoom) => {
      const newTeam = prevRoom.teams[newTeamId];
      if (!canJoinTeam(userId, newTeam)) return prevRoom;
      let newRoom = prevRoom;
      const oldTeamId = getTeamIdForUser(userId, prevRoom.teams);
      if (oldTeamId) {
        newRoom = getRoomWithUserRemovedFromTeam(newRoom, oldTeamId, userId);
      }
      newRoom = getRoomWithUserAddedToTeam(newRoom, newTeamId, userId);
      return newRoom;
    });
  };
}

export function useAddTeamToRoom() {
  const { setRoom } = useRoomStore();
  return (roomId: string, team: Team) => {
    setRoom(roomId, (prevRoom) => {
      return {
        ...prevRoom,
        teams: {
          ...prevRoom.teams,
          [team.id]: team,
        },
        teamOrder: [...prevRoom.teamOrder, team.id],
      };
    });
  };
}

export function useRemoveTeamFromRoom() {
  const { setRoom } = useRoomStore();
  return (roomId: string, team: Team) => {
    setRoom(roomId, (prevRoom) => {
      if (!canRemoveTeam(team)) return prevRoom;
      return {
        ...prevRoom,
        teams: excludeKey(prevRoom.teams, team.id),
        teamOrder: prevRoom.teamOrder.filter((teamId) => teamId !== team.id),
      };
    });
  };
}

// Fetchers
export function useFetchRooms() {
  const { setRoom } = useRoomStore();
  return async () => {
    const rooms = await getActiveRooms();
    rooms.forEach((room) => {
      setRoom(room.id, () => room);
    });
  };
}

export function useFetchRoom() {
  const { setRoom } = useRoomStore();
  return async (id: string) => {
    const room = await getRoom(id);
    setRoom(room.id, () => room);
    return room;
  };
}

export function useCreateRoom() {
  const { setRoom } = useRoomStore();
  return async (name: string, isPrivate: boolean) => {
    const [room] = await createRoom({ name, isPrivate });
    setRoom(room.id, () => room);
    return room;
  };
}

export function useUpdateRoom() {
  const { setRoom } = useRoomStore();
  return async (
    id: string,
    updatedRoomSettings: {
      name?: string;
      hasTeams?: boolean;
      canPlaySneakTurns?: boolean;
      jokerCount?: number;
      startingHandCount?: number;
    }
  ) => {
    const [room] = await updateRoom(id, updatedRoomSettings);
    setRoom(room.id, () => room);
    return room;
  };
}

export function subscribeRoom(
  roomId: string,
  callback: (response: { room: Room }) => void
) {
  return subscribe<{ rooms: Room[] }>({
    endpoint: `/subscribe/rooms/${roomId}`,
    callback: ({ rooms }) => callback({ room: rooms[0] }),
  });
}

export function useSubscribeRoom(roomId: string | undefined) {
  const fetchUsers = useFetchUsers();
  const { getUser } = useUserStore();
  const { setRoom } = useRoomStore();
  const onSubscriptionUpdate = useCallback(
    ({ room }) => {
      setRoom(room.id, () => room);
      const allUsersInRoom = getAllUserIdsInRoom(room);
      const newUsers = allUsersInRoom.filter((userId) => {
        const user = getUser(userId);
        return !user;
      });
      fetchUsers(newUsers);
    },
    [setRoom, getUser, fetchUsers]
  );
  const onSubscriptionUpdateRef = useBoundRef(onSubscriptionUpdate);
  useEffect(() => {
    if (!roomId) return;
    // NB. The use of the function below is critical for using the most up to date version of onSubscriptionUpdateRef
    const unsubscribeRoom = subscribeRoom(roomId, (args) => {
      onSubscriptionUpdateRef.current(args);
    });
    return unsubscribeRoom;
  }, [roomId]);
}
