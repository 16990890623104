import React, { useCallback, useContext, useEffect } from 'react';
import { createContext, useState } from 'react';
import { subscribe } from '../../api-client/core';
import { sendGameAction } from '../../api-client/gameActions';
import { createGame, getGame } from '../../api-client/games';
import { getAllUserIdsInGame } from '../../lib/game';
import { optionalToObject } from '../../lib/general';
import { CardValue } from '../../types/Card';
import { PlayerGamePartial } from '../../types/Game';
import { GameActionType } from '../../types/GameActions';
import { Dict } from '../../types/General';
import { Room } from '../../types/Room';
import { useBoundRef } from '../useBoundRef';
import { useRoomStore } from './room';
import { useFetchUsers, useUserStore } from './user';

// Store
const GameStoreContext = createContext<Dict<PlayerGamePartial>>({});
const SetGameStoreContext = createContext<
  React.Dispatch<React.SetStateAction<Dict<PlayerGamePartial>>>
>(() => {});

export const GameStoreProvider: React.FC = ({ children }) => {
  const [gameStore, setGameStore] = useState<Dict<PlayerGamePartial>>({});
  return (
    <GameStoreContext.Provider value={gameStore}>
      <SetGameStoreContext.Provider value={setGameStore}>
        {children}
      </SetGameStoreContext.Provider>
    </GameStoreContext.Provider>
  );
};

export function useGameStore() {
  const gameStore = useContext(GameStoreContext);
  const setGameStore = useContext(SetGameStoreContext);
  const setGame = (
    gameId: string,
    transformGame: (prevGame: PlayerGamePartial) => PlayerGamePartial
  ) => {
    setGameStore((prevState) => {
      const newGame = transformGame(prevState[gameId]);
      return {
        ...prevState,
        [gameId]: newGame,
      };
    });
  };
  const getGame = (id: string) => gameStore[id];
  return { getGame, setGame };
}

export function useGame(gameId: string | undefined) {
  const { getGame } = useGameStore();
  if (!gameId) return null;
  return getGame(gameId);
}

// Actions
export function useDrawCard() {
  const { setGame } = useGameStore();
  const { setRoom } = useRoomStore();
  return async (roomId: string, gameId: string) => {
    const { game, room } = await sendGameAction(roomId, gameId, {
      type: GameActionType.DrawCard,
    });
    setGame(game.id, () => game);
    if (room) {
      setRoom(room.id, () => room);
    }
  };
}

export function usePlayCard() {
  const { setGame } = useGameStore();
  const { setRoom } = useRoomStore();
  return async (
    roomId: string,
    gameId: string,
    card: CardValue,
    overriddenCard: CardValue | null
  ) => {
    const { game, room } = await sendGameAction(roomId, gameId, {
      type: GameActionType.PlayCard,
      playedCardValue: card,
      ...optionalToObject('overriddenCardValue', overriddenCard),
    });
    setGame(game.id, () => game);
    if (room) {
      setRoom(room.id, () => room);
    }
  };
}

export function useEndTurn() {
  const { setGame } = useGameStore();
  const { setRoom } = useRoomStore();
  return async (roomId: string, gameId: string) => {
    const { game, room } = await sendGameAction(roomId, gameId, {
      type: GameActionType.EndTurn,
    });
    setGame(game.id, () => game);
    if (room) {
      setRoom(room.id, () => room);
    }
  };
}

export function useLeaveGame() {
  const { setGame } = useGameStore();
  const { setRoom } = useRoomStore();
  return async (roomId: string, gameId: string) => {
    const { game, room } = await sendGameAction(roomId, gameId, {
      type: GameActionType.LeaveGame,
    });
    setGame(game.id, () => game);
    if (room) {
      setRoom(room.id, () => room);
    }
  };
}

// Fetchers
export function useFetchGame() {
  const { setGame } = useGameStore();
  return async (roomId: string, gameId: string) => {
    const game = await getGame(roomId, gameId);
    setGame(game.id, () => game);
    return game;
  };
}

export function useCreateGame() {
  const { setGame } = useGameStore();
  const { setRoom } = useRoomStore();
  return async (roomId: string) => {
    const { game, room } = await createGame(roomId);
    setGame(game.id, () => game);
    setRoom(room.id, () => room);
    return game;
  };
}

export function subscribeGame(
  gameId: string,
  callback: (response: { game: PlayerGamePartial; room?: Room }) => void
) {
  return subscribe<{ games: PlayerGamePartial[]; rooms: Room[] }>({
    endpoint: `/subscribe/games/${gameId}`,
    callback: ({ games, rooms }) =>
      callback({ game: games[0], ...optionalToObject('room', rooms[0]) }),
  });
}

export function useSubscribeGame(gameId: string | undefined) {
  const { setGame } = useGameStore();
  const { setRoom } = useRoomStore();
  const { getUser } = useUserStore();
  const fetchUsers = useFetchUsers();
  const onSubscriptionUpdate = useCallback(
    ({ game, room }) => {
      setGame(game.id, () => game);
      if (room) {
        setRoom(room.id, () => room);
      }
      const allUsersInGame = getAllUserIdsInGame(game);
      const newUsers = allUsersInGame.filter((userId) => {
        const user = getUser(userId);
        return !user;
      });
      fetchUsers(newUsers);
    },
    [setGame, setRoom, getUser, fetchUsers]
  );
  const onSubscriptionUpdateRef = useBoundRef(onSubscriptionUpdate);
  useEffect(() => {
    if (!gameId) return;
    // NB. The use of the function below is critical for using the most up to date version of onSubscriptionUpdateRef
    const unsubscribeGame = subscribeGame(gameId, (args) => {
      onSubscriptionUpdateRef.current(args);
    });
    return unsubscribeGame;
  }, [gameId]);
}
