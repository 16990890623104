import React, { useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { CardValue } from '../../types/Card';
import { CARD_EXIT_DURATION } from '../PlayingCard/playingCardConstants';
import PlayingCardPlaceholder from '../PlayingCardPlaceholder/PlayingCardPlaceholder';
import VisiblePlayingCard from '../VisiblePlayingCard/VisiblePlayingCard';

interface DiscardProps {
  topDiscardValue: CardValue;
}

export function Discard({ topDiscardValue }: DiscardProps) {
  const discardRef = useRef<HTMLDivElement>(null);
  return (
    <TransitionGroup>
      <CSSTransition
        key={topDiscardValue.id}
        // Make the timeout a little less to avoid flashes
        timeout={CARD_EXIT_DURATION * 0.95}
        classNames="gamePage__discard--transition"
        // TODO: using this causes only one element to be transitioned. W/o we get errors
        // nodeRef={discardRef}
      >
        {topDiscardValue ? (
          <VisiblePlayingCard value={topDiscardValue} ref={discardRef} />
        ) : (
          <PlayingCardPlaceholder ref={discardRef} />
        )}
      </CSSTransition>
    </TransitionGroup>
  );
}
