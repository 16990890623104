import { Room } from '../types/Room';
import { callApi } from './core';

export async function createTeam({ roomId }: { roomId: string }) {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms/${roomId}/teams`,
    method: 'POST',
  });
  return rooms;
}

export async function removeTeam({
  roomId,
  teamId,
}: {
  roomId: string;
  teamId: string;
}) {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms/${roomId}/teams/${teamId}`,
    method: 'DELETE',
  });
  return rooms;
}

export async function updateTeamName(
  {
    roomId,
    teamId,
    updateSettings,
  }: {
    roomId: string;
    teamId: string;
    updateSettings: { name: string };
  },
  options?: { shouldKeepAlive?: boolean }
) {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms/${roomId}/teams/${teamId}/name`,
    method: 'PATCH',
    requestBody: updateSettings,
    shouldKeepAlive: options?.shouldKeepAlive,
  });
  return rooms;
}

export async function updateTeamUserIds(
  {
    roomId,
    teamId,
    userIdIndex,
    updateSettings,
  }: {
    roomId: string;
    teamId: string;
    userIdIndex: 0 | 1;
    updateSettings: { userId: string | null };
  },
  options?: { shouldKeepAlive?: boolean }
) {
  const { rooms } = await callApi<{ rooms: Room[] }>({
    endpoint: `/rooms/${roomId}/teams/${teamId}/userIds/${userIdIndex}`,
    method: 'PATCH',
    requestBody: updateSettings,
    shouldKeepAlive: options?.shouldKeepAlive,
  });
  return rooms;
}
