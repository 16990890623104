import React, { useEffect } from 'react';
import './App.css';
import GamePage from './pages/GamePage/GamePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import Header from './ui/Header/Header';
import RulesModal from './ui/RulesModal/RulesModal';
import RoomPage from './pages/RoomPage/RoomPage';
import { RoomsStoreProvider } from './hooks/store/room';
import { UsersStoreProvider } from './hooks/store/user';
import {
  AuthenticationStoreProvider,
  useAuthenticate,
} from './hooks/store/authentication';
import { GameStoreProvider } from './hooks/store/game';
import { ModalManager } from './ui/Modal/Modal';

function App() {
  return (
    <UsersStoreProvider>
      <AuthenticationStoreProvider>
        <RoomsStoreProvider>
          <GameStoreProvider>
            <Router>
              <AppInitialization>
                <ModalManager>
                  <Header />
                  <Routes>
                    <Route
                      path="/room/:roomId/game/:gameId"
                      element={<GamePage />}
                    />
                    <Route path="/room/:roomId" element={<RoomPage />} />
                    <Route path="/" element={<HomePage />} />
                  </Routes>
                  <RulesModal />
                </ModalManager>
              </AppInitialization>
            </Router>
          </GameStoreProvider>
        </RoomsStoreProvider>
      </AuthenticationStoreProvider>
    </UsersStoreProvider>
  );
}

const AppInitialization: React.FC = ({ children }) => {
  const authenticate = useAuthenticate();

  // Initial call to authenticate
  useEffect(() => {
    authenticate();
  }, []);

  return <>{children}</>;
};

export default App;
