import { ExponentialTimer } from '../lib/exponential-timer';

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      // Placehoder for future env vars
    }
  }
}

export type ApiMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type CallApiParams = {
  endpoint: string;
  requestBody?: any;
  queryParams?: any;
  method?: ApiMethods;
  shouldKeepAlive?: boolean;
};

export async function callApi<ResponseBody>({
  endpoint,
  requestBody,
  queryParams,
  method = 'GET',
  shouldKeepAlive,
}: CallApiParams) {
  const route = buildRoute({
    pathname: endpoint,
    search: queryParams ? new URLSearchParams(queryParams) : undefined,
  });
  const response = await fetch(route, {
    method,
    headers: {
      ...(requestBody && { 'Content-Type': 'application/json' }),
    },
    body: requestBody && JSON.stringify(requestBody),
    credentials: 'include',
    keepalive: shouldKeepAlive,
  });
  const responseBody = await response.json();
  if (responseBody.error) {
    throw new Error(responseBody.error);
  }
  return responseBody.data as ResponseBody;
}

type SubscribeApiParams<EventShape> = {
  endpoint: string;
  callback: (event: EventShape) => void;
};

export function subscribe<ResponseBody>({
  endpoint,
  callback,
}: SubscribeApiParams<ResponseBody>) {
  const route = buildRoute({
    pathname: endpoint,
  });
  let source: EventSource | null = null;
  const timer = new ExponentialTimer({
    defaultTimeoutInMs: 1000,
    exponentialFactor: 2,
  });

  function handleFailedConnection() {
    terminateConnection();
    timer.setTimeout(() => {
      initializeConnection();
    });
  }

  function handleEndConnection() {
    terminateConnection();
    timer.clearTimeout();
  }

  function handleSuccessfulConnection() {
    timer.clearTimeout();
  }

  function terminateConnection() {
    source?.close();
    source = null;
  }

  function initializeConnection() {
    source = new EventSource(route, { withCredentials: true });
    source.onopen = () => {
      handleSuccessfulConnection();
    };
    source.onmessage = (event: MessageEvent) => {
      if (event.lastEventId === '-1') {
        handleFailedConnection();
      } else {
        callback(JSON.parse(event.data).data as ResponseBody);
      }
    };
    source.onerror = () => {
      handleFailedConnection();
    };
  }

  initializeConnection();

  return () => {
    handleEndConnection();
  };
}

function normalizePath(path: string) {
  let normalizedPath = path;
  while (normalizedPath[0] === '/') {
    normalizedPath = normalizedPath.slice(1, normalizedPath.length);
  }
  while (normalizedPath[normalizedPath.length - 1] === '/') {
    normalizedPath = normalizedPath.slice(0, normalizedPath.length - 1);
  }
  return normalizedPath;
}

export function buildRoute({
  pathname,
  search,
}: {
  pathname: string;
  search?: URLSearchParams;
}) {
  const searchString = search?.toString() ? '?' + search?.toString() : '';
  return `/api/${normalizePath(pathname)}${searchString}`;
}
