import {
  DefaultRoomName,
  DraftRoom,
  MaxTeamCount,
  MinTeamCount,
  Room,
} from '../types/Room';
import { isNotNull } from './general';
import { getTeamsInOrder, isValidTeam } from './team';

// export function getLatestWinningTeamId(games: Game[]) {
// 	if (games.length === 0) {
// 		return null;
// 	}
// 	const latestGame = games[games.length - 1];
// 	const latestWinningPlayerIds = latestGame
// }

export function getAllUserIdsInRoom(room: Room) {
  return getTeamsInOrder(room.teams, room.teamOrder)
    .flatMap((team) => team.userIds)
    .filter(isNotNull);
}

export function createDraftRoom(): DraftRoom {
  return {
    name: DefaultRoomName,
    isPrivate: false,
  };
}

export function canAddTeamToRoom(room: Room) {
  return room.teamOrder.length < MaxTeamCount;
}

export function canRemoveTeamFromRoom(room: Room) {
  return room.teamOrder.length > MinTeamCount;
}

export function isUserIdInRoom(room: Room, targetUserId: string) {
  return getAllUserIdsInRoom(room).includes(targetUserId);
}

export function getOrderForNewTeam(room: Room) {
  const teams = getTeamsInOrder(room.teams, room.teamOrder);
  const lastTeam = teams[teams.length - 1];
  return lastTeam.order + 1;
}

export function hasAllValidTeams(room: Room) {
  if (!room.hasTeams) return getAllUserIdsInRoom(room).length > 1;
  const teamsInOrder = getTeamsInOrder(room.teams, room.teamOrder);
  if (teamsInOrder.length < 2) return false;
  return teamsInOrder.every(isValidTeam);
}
