import React, { useEffect, useRef, useState } from 'react';
import { useTimeoutState } from '../../hooks/useTimeoutState';
import './ClickToCopy.css';

interface ClickToCopyProps {
  children: string;
}

const ClickToCopy = ({ children }: ClickToCopyProps) => {
  const [message, setMessage] = useTimeoutState<string | null>(null, 3000);
  const textNodeRef = useRef<HTMLSpanElement | null>(null);

  const handleCopy = () => {
    if (!textNodeRef.current) return;
    const selection = window.getSelection();
    if (!selection) return;
    const range = document.createRange();
    range.selectNode(textNodeRef.current);
    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }
    selection.addRange(range);
    const wasSuccessful = document.execCommand('copy');
    if (wasSuccessful) {
      setMessage('Copied share link to clipboard.');
    } else {
      setMessage('Unable to copy share link to clipboard.');
    }
  };

  return (
    <span className="clickToCopy">
      <span ref={textNodeRef} className="clickToCopy__text">
        {children}
      </span>
      <button onClick={handleCopy}>Copy</button>
      {message && <span>{message}</span>}
    </span>
  );
};

export default ClickToCopy;
