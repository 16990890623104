import { CardValue } from './Card';

export enum GameActionType {
  DrawCard = 'DRAW_CARD',
  PlayCard = 'PLAY_CARD',
  EndTurn = 'END_TURN',
  LeaveGame = 'LEAVE_GAME',
}

export type DrawCardAction = {
  type: GameActionType.DrawCard;
};

export type PlayCardAction = {
  type: GameActionType.PlayCard;
  playedCardValue: CardValue;
  overriddenCardValue?: CardValue; // For when aces are used as wild cards
};

export type EndTurnAction = {
  type: GameActionType.EndTurn;
};

export type LeaveGameAction = {
  type: GameActionType.LeaveGame;
};

export type GameAction =
  | DrawCardAction
  | PlayCardAction
  | EndTurnAction
  | LeaveGameAction;
