import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHash } from '../../lib/routing';
import { Modal } from '../Modal/Modal';

export default function RulesModal() {
  const hash = useHash();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('#');
  }, [navigate]);
  if (hash !== '#rules') return null;
  return (
    <Modal onClose={handleClose}>
      <h2>The Rules</h2>
      <p>Draw the joker and you're out!</p>
      <p>
        <Link to="#">Close</Link>
      </p>
    </Modal>
  );
}
