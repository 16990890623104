import React from 'react';
import cn from 'classnames';
import './DirectionArrow.css';

interface DirectionArrowProps {
  flipHorizontal?: boolean;
  hidden?: boolean;
}

function DirectionArrow({
  flipHorizontal = false,
  hidden = false,
}: DirectionArrowProps) {
  if (hidden) {
    return <div className="directionArrow__placeholder" />;
  }
  return (
    <svg
      id="Solid"
      className={cn('directionArrow', {
        'directionArrow--flipHorizontal': flipHorizontal,
      })}
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m365.291 168.033a295.963 295.963 0 0 0 -293.291 295.967 24 24 0 0 1 -48 0 343.66 343.66 0 0 1 341.376-343.968l31.958 23.968zm-14.891 91.167 128-96a24 24 0 0 0 0-38.4l-128-96a24 24 0 1 0 -28.8 38.4l102.4 76.8-102.4 76.8a24 24 0 1 0 28.8 38.4z" />
    </svg>
  );
}

export default DirectionArrow;
