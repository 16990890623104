export interface Team {
  id: string;
  createdOn: string;
  status: TeamStatus;
  name: string;
  order: number;
  userIds: [string | null, string | null];
}

export enum TeamStatus {
  Active = 'active', // Team still is in use
  Reitred = 'retired', // Team is soft deleted
}

export function castTeamStatus(statusString: string) {
  switch (statusString) {
    case 'active':
      return TeamStatus.Active;
    case 'retired':
      return TeamStatus.Reitred;
    default:
      console.warn(`Unidentified team status used: ${statusString}`);
      return TeamStatus.Active;
  }
}

export function getDefaultTeamName() {
  return `New Team`;
}

export function isValidTeamName(proposedName: string) {
  return (
    typeof proposedName === 'string' &&
    proposedName.length >= MinTeamNameLength &&
    proposedName.length <= MaxTeamNameLength
  );
}

export const TeamMaxSize = 2;

export const MinTeamNameLength = 1;
export const MaxTeamNameLength = 100;
