import React, { createContext, useContext, useState } from 'react';
import { getUsers, updateUser } from '../../api-client/users';
import { Dict } from '../../types/General';
import { User } from '../../types/User';
import { useOwnUserId } from './authentication';

// Store
const UsersStoreContext = createContext<Dict<User>>({});
const SetUsersStoreContext = createContext<
  React.Dispatch<React.SetStateAction<Dict<User>>>
>(() => {});

export const UsersStoreProvider: React.FC = ({ children }) => {
  const [usersStore, setUsersStore] = useState<Dict<User>>({});
  return (
    <UsersStoreContext.Provider value={usersStore}>
      <SetUsersStoreContext.Provider value={setUsersStore}>
        {children}
      </SetUsersStoreContext.Provider>
    </UsersStoreContext.Provider>
  );
};

export function useUserStore() {
  const usersStore = useContext(UsersStoreContext);
  const setUsersStore = useContext(SetUsersStoreContext);
  const setUser = (user: User) => {
    setUsersStore((prevState) => ({
      ...prevState,
      [user.id]: user,
    }));
  };
  const getUser = (id: string) => usersStore[id];
  return { usersStore, getUser, setUser };
}

// Selectors
export function useUser(id: string) {
  const { getUser } = useUserStore();
  return getUser(id);
}

// Actions
export function useSetUserName() {
  const { getUser, setUser } = useUserStore();
  const ownUserId = useOwnUserId();
  return async (id: string, name: string) => {
    if (id !== ownUserId) return;
    const updatedUser = await updateUser(ownUserId, name);
    setUser({
      ...getUser(id),
      name: updatedUser.name,
    });
  };
}

// Fetchers
export function useFetchUsers() {
  const { setUser } = useUserStore();
  return async (userIds: string[]) => {
    if (userIds.length === 0) return;
    const users = await getUsers(userIds);
    users.forEach((user) => {
      setUser(user);
    });
  };
}
