import React from 'react';
import { useUpdateRoom } from '../../hooks/store/room';
import {
  MaxJokerCount,
  MaxStartingHandCount,
  MinJokerCount,
  MinStartingHandCount,
  Room,
} from '../../types/Room';
import CheckBox from '../../ui/Forms/CheckBox';
import CounterInput from '../../ui/Forms/CounterInput';

interface GameOptionsFormProps {
  room: Room;
}

export default function GameOptionsForm({ room }: GameOptionsFormProps) {
  const updateRoom = useUpdateRoom();
  return (
    <div>
      <h2>Game Options</h2>
      <p>
        Team Survival:
        <CheckBox
          value={room.hasTeams}
          onChange={(hasTeams) => updateRoom(room.id, { hasTeams })}
        />
      </p>
      <p>
        Speed Survival:
        <CheckBox
          value={room.canPlaySneakTurns}
          onChange={(canPlaySneakTurns) =>
            updateRoom(room.id, { canPlaySneakTurns })
          }
        />
      </p>
      <p>
        Number of Jokers:
        <CounterInput
          value={room.jokerCount}
          onChange={(jokerCount) => updateRoom(room.id, { jokerCount })}
          maxValue={MaxJokerCount}
          minValue={MinJokerCount}
        />
      </p>
      <p>
        Starting Hand Size:
        <CounterInput
          value={room.startingHandCount}
          onChange={(startingHandCount) =>
            updateRoom(room.id, { startingHandCount })
          }
          maxValue={MaxStartingHandCount}
          minValue={MinStartingHandCount}
        />
      </p>
    </div>
  );
}
