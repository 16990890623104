import { Dict } from '../types/General';
import {
  MaxJokerCount,
  MaxStartingHandCount,
  MinJokerCount,
  MinStartingHandCount,
} from '../types/Room';
import { Team, TeamMaxSize } from '../types/Team';
import { isNotNull } from './general';

export function getTeamMemberCount(team: Team) {
  return team.userIds.filter(isNotNull).length;
}

export function isValidTeam(team: Team) {
  return getTeamMemberCount(team) === 2;
}

export function canJoinTeam(userId: string, team: Team) {
  return (
    getTeamMemberCount(team) < TeamMaxSize && !isUserInTeam(team, userId) // Must not exceed max size
  ); // User cannot already be on the team
}

export function isUserInTeam(team: Team, targetUserId: string) {
  return getUserIndexInTeam(team, targetUserId) !== null;
}

export function getUserIndexInTeam(team: Team, targetUserId: string) {
  const index = team.userIds.indexOf(targetUserId);
  if (index === 0) return 0;
  if (index === 1) return 1;
  return null;
}

export function canLeaveTeam(userId: string, team: Team) {
  return isUserInTeam(team, userId); // User must be on the team
}

export function getIdForFirstAvailableTeam(
  userId: string,
  teamDict: Dict<Team>
) {
  return Object.values(teamDict).find((team) => canJoinTeam(userId, team))?.id;
}

export function isUserInSomeTeam(userId: string, teamDict: Dict<Team>) {
  return Object.values(teamDict).some((team) => team.userIds.includes(userId));
}

export function getTeamIdForUser(userId: string, teamDict: Dict<Team>) {
  return Object.values(teamDict).find((team) => team.userIds.includes(userId))
    ?.id;
}

export function isValidJokerCount(jokerCount: number) {
  return (
    jokerCount <= MaxJokerCount ||
    jokerCount >= MinJokerCount ||
    jokerCount % 1 === 0
  );
}

export function isValidStartingHandCount(startingHandCount: number) {
  return (
    startingHandCount <= MaxStartingHandCount ||
    startingHandCount >= MinStartingHandCount ||
    startingHandCount % 1 === 0
  );
}

export function canRemoveTeam(team: Team) {
  return getTeamMemberCount(team) === 0;
}

export function canSetTeamName(team: Team, userId: string) {
  return team.userIds.includes(userId);
}

export function getTeamOpeningIndex(team: Team) {
  if (team.userIds[0] === null) return 0;
  if (team.userIds[1] === null) return 1;
  return null;
}

export function getUserIdsWithNewMember(
  userId: string,
  team: Team
): [string | null, string | null] {
  const openingIndex = getTeamOpeningIndex(team);
  const newTeamUserIds: [string | null, string | null] = [
    team.userIds[0],
    team.userIds[1],
  ];
  if (openingIndex === null) return newTeamUserIds;
  newTeamUserIds[openingIndex] = userId;
  return newTeamUserIds;
}

export function getUserIdsWithoutMember(
  userId: string,
  team: Team
): [string | null, string | null] {
  return [
    team.userIds[0] === userId ? null : team.userIds[0],
    team.userIds[1] === userId ? null : team.userIds[1],
  ];
}

export function getTeamIdsInOrder(teams: Team[]) {
  const teamsCopy = teams.slice();
  teamsCopy.sort((a, b) => a.order - b.order);
  return teamsCopy.map((team) => team.id);
}

export function getTeamsInOrder(teams: Dict<Team>, teamOrder: string[]) {
  return teamOrder.map((teamId) => teams[teamId]);
}
