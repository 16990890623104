import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { noop } from '../../lib/general';
import './Modal.css';

const ModalContext = createContext<{
  pushModal: (modalContents: ReactElement) => void;
  popModal: (modalContents: ReactElement) => void;
}>({
  pushModal: noop,
  popModal: noop,
});

export const ModalManager: React.FC = ({ children: restOfPage }) => {
  const [modalStack, setModalStack] = useState<ReactElement[]>([]);
  const pushModal = useCallback(
    (modalContents: ReactElement) => {
      setModalStack((prevModalStack) => [...prevModalStack, modalContents]);
    },
    [setModalStack]
  );
  const popModal = useCallback(
    (modalContents: ReactElement) => {
      setModalStack((prevModalStack) =>
        prevModalStack.filter(
          (givenModalContents) => givenModalContents !== modalContents
        )
      );
    },
    [setModalStack]
  );
  const [currentModalContents] = modalStack;
  return (
    <ModalContext.Provider
      value={{
        pushModal,
        popModal,
      }}
    >
      {restOfPage}
      {currentModalContents}
    </ModalContext.Provider>
  );
};

export interface ModalContainerProps {
  onClose?: () => void;
}

export const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  onClose,
}) => {
  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);
  // Listener for Escape key
  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    document.body.addEventListener('keyup', handleKeyUp);
    return () => document.body.removeEventListener('keyup', handleKeyUp);
  }, [handleClose]);
  return (
    <div className="modal__overlay" onClick={handleClose}>
      <div
        className="modal__container"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="modal__background">{children}</div>
      </div>
    </div>
  );
};

export interface ModalProps {
  onClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  const { pushModal, popModal } = useContext(ModalContext);
  // Memoize component to avoid unnecessary rerenders
  const contents = useMemo(
    () => <ModalContainer onClose={onClose}>{children}</ModalContainer>,
    [children, onClose]
  );
  // Listen for changes and handle adding/removing from active modals
  useEffect(() => {
    pushModal(contents);
    return () => popModal(contents);
  }, [contents, pushModal, popModal]);
  // Return nothing
  return null;
};
