import { Team } from './Team';
import { CardValue } from './Card';
import { Turn } from './Turn';
import { PlayerSummary, OwnPlayer, Player } from './Player';
import { Dict } from './General';

// The full game state as seen only on the server
export interface Game {
  id: string;
  status: GameStatus;
  createdOn: string;
  roomId: string;
  stepNumber: number; // Not turn #, rather # of updates from server
  teams: Dict<Team>;
  players: Dict<Player>;
  currentTurn: Turn;
  teamOrder: string[]; // By playerId
  isDirectionForward: boolean; // Clockwise if true
  deck: CardValue[];
  discard: CardValue[];
  discardTopOverride: CardValue | null; // For ace suit selection
  canPlaySneakTurns: boolean; // Rules like speed survival
  jokerCount: number;
}

type GamePrivateKeys = 'players' | 'deck';

type GamePublicProperties = Omit<Game, GamePrivateKeys>;

type GamePersonalProperties = {
  ownPlayer: OwnPlayer;
  playerSummaries: Dict<PlayerSummary>;
  deckLength: number;
};

// The portion of the game state visible to each player
export type PlayerGamePartial = GamePublicProperties & GamePersonalProperties;

export enum GameStatus {
  Active = 'active', // Player is still playing the game
  Inactive = 'inactive', // Player is still playing the game
  Complete = 'complete', // Player is still playing the game
}

export function castGameStatus(statusString: string) {
  switch (statusString) {
    case 'active':
      return GameStatus.Active;
    case 'inactive':
      return GameStatus.Inactive;
    case 'complete':
      return GameStatus.Complete;
    default:
      console.warn(`Unidentified player status used: ${statusString}`);
      return GameStatus.Active;
  }
}
