import { useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import {
  CARD_ENTER_DURATION,
  CARD_EXIT_DURATION,
} from '../ui/PlayingCard/playingCardConstants';

interface UserAnimateCardsProps {
  elementRef: React.RefObject<HTMLDivElement>;
  isOwnUser: boolean;
  totalCardCount: number;
  transitionExtCounter: number;
  onTransitionExit: () => void;
  transitionProps: Partial<CSSTransitionProps>;
}

export function useAnimateCards({
  elementRef,
  isOwnUser,
  totalCardCount,
  transitionExtCounter,
  onTransitionExit,
  transitionProps,
}: UserAnimateCardsProps) {
  const hasBeenDealtRef = useRef<boolean>(false);
  const hasBeenPlayedRef = useRef<boolean>(false);
  const oldBoundsRef = useRef<DOMRect | null>(null);
  const oldTransformedBoundsRef = useRef<DOMRect | null>(null);
  const oldAnimationRef = useRef<Animation | null>(null);

  // Keep track of previous location
  useEffect(() => {
    let frameId: number;
    const handleAnimationFrame = () => {
      if (elementRef.current) {
        oldTransformedBoundsRef.current =
          elementRef.current.getBoundingClientRect();
        const transform = elementRef.current.style.transform;
        elementRef.current.style.transform = 'none';
        oldBoundsRef.current = elementRef.current.getBoundingClientRect();
        elementRef.current.style.transform = transform;
        frameId = requestAnimationFrame(handleAnimationFrame);
      }
    };
    handleAnimationFrame();
    return () => cancelAnimationFrame(frameId);
  }, []);

  // Entrance animation: Moving from deck to hand
  useLayoutEffect(() => {
    const deckElement = document.getElementById('gamePage__deck');
    const cardElement = elementRef.current;

    if (!deckElement || !cardElement) return;

    const oldBounds = deckElement.getBoundingClientRect();
    const newBounds = cardElement.getBoundingClientRect();
    const changeLeft = oldBounds.left - newBounds.left;
    const changeTop = oldBounds.top - newBounds.top;

    const animation = cardElement.animate(
      isOwnUser
        ? [
            {
              // Invert
              transform: `translate(${changeLeft}px, ${changeTop}px) scale(100%, 100%) rotateY(180deg)`,
              // pointerEvents: 'none',
              easing: 'ease-out',
            },
            {
              // Middle
              transform: `translate(${changeLeft / 2}px, ${
                changeTop / 2
              }px) scale(150%, 150%) rotateY(0deg)`,
              easing: 'ease-in',
            },
            {
              // Play
              transform: 'translate(0px, 0px) scale(100%, 100%) rotateY(0deg)',
              // pointerEvents: 'initial',
              easing: 'ease-in',
            },
          ]
        : [
            {
              // Invert
              transform: `translate(${
                changeLeft + oldBounds.width
              }px, ${changeTop}px) rotateY(180deg)`,
              easing: 'ease-out',
            },
            {
              // Play
              transform: 'translate(0px, 0px) rotateY(180deg)',
              easing: 'ease-in',
            },
          ],
      {
        duration: CARD_ENTER_DURATION,
        fill: 'forwards',
      }
    );
    // animation.commitStyles();
    oldAnimationRef.current = animation;
  }, []);

  // Shifting animation: Moving from hand to other place in hand
  useLayoutEffect(() => {
    if (!hasBeenDealtRef.current) {
      hasBeenDealtRef.current = true;
      return;
    }

    if (!transitionProps.in || hasBeenPlayedRef.current) {
      hasBeenPlayedRef.current = true;
      return;
    }

    const cardElement = elementRef.current;

    if (
      !cardElement ||
      !oldBoundsRef.current ||
      !oldTransformedBoundsRef.current
    )
      return;

    const oldBounds = oldBoundsRef.current;
    const transform = cardElement.style.transform;
    cardElement.style.transform = 'none';
    const newBounds = cardElement.getBoundingClientRect();
    const changeLeft = oldBounds.left - newBounds.left;
    const changeTop = oldBounds.top - newBounds.top;

    // Bail if no update needed
    if (changeLeft === 0 && changeTop === 0) {
      cardElement.style.transform = transform;
      return;
    }

    // These values account for the actual position of the element on the page with the transform
    const trueChangeLeft =
      oldTransformedBoundsRef.current.left - newBounds.left;
    const trueChangeTop = oldTransformedBoundsRef.current.top - newBounds.top;

    oldAnimationRef.current?.cancel();
    const animation = cardElement.animate(
      isOwnUser
        ? [
            {
              // Invert
              transform: `translate(${trueChangeLeft}px, ${trueChangeTop}px)`,
            },
            {
              // Play
              transform: 'translate(0px, 0px)',
            },
          ]
        : [
            {
              // Invert
              transform: `translate(${trueChangeLeft}px, ${trueChangeTop}px) rotateY(180deg)`,
            },
            {
              // Play
              transform: 'translate(0px, 0px) rotateY(180deg)',
            },
          ],
      {
        duration: 600,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    );
    // animation.commitStyles();
    oldAnimationRef.current = animation;
  }, [totalCardCount, transitionExtCounter, transitionProps.in]);

  // Ending animation: Moving from hand to discard
  useLayoutEffect(() => {
    if (transitionProps.in) return;

    const cardElement = elementRef.current;
    const discardElement = document.getElementById('gamePage__discard');

    if (
      !cardElement ||
      !discardElement ||
      !oldBoundsRef.current ||
      !oldTransformedBoundsRef.current
    )
      return;

    const oldBounds = cardElement.getBoundingClientRect();
    const newBounds = discardElement.getBoundingClientRect();
    const changeLeft = oldBounds.left - newBounds.left;
    const changeTop = oldBounds.top - newBounds.top;

    // These values account for the actual position of the element on the page with the transform
    const currentTransformLeft =
      oldTransformedBoundsRef.current.left - oldBoundsRef.current.left;
    const currentTransformTop =
      oldTransformedBoundsRef.current.top - oldBoundsRef.current.top;

    oldAnimationRef.current?.cancel();
    const animation = cardElement.animate(
      isOwnUser
        ? [
            {
              // Invert
              transform: `translate(${currentTransformLeft}px, ${currentTransformTop}px)`,
              // pointerEvents: 'none',
            },
            {
              // Play
              transform: `translate(${changeLeft * -1}px, ${changeTop * -1}px)`,
              // pointerEvents: 'none',
            },
          ]
        : [
            {
              // Invert
              transform: `translate(${currentTransformLeft}px, ${currentTransformTop}px) scale(100%, 100%) rotateY(180deg)`,
              easing: 'ease-out',
            },
            {
              // Middle
              transform: `translate(${
                (changeLeft * -1 + newBounds.width) / 2
              }px, ${(changeTop * -1) / 2}px) scale(150%, 150%) rotateY(0deg)`,
              easing: 'ease-in',
            },
            {
              // Play
              // TODO: find out why the `+ newBounds.height / 4` adjustment is needed in this case
              transform: `translate(${changeLeft * -1 + newBounds.width}px, ${
                changeTop * -1
              }px) scale(100%, 100%) rotateY(0deg)`,
              easing: 'ease-in',
            },
          ],
      {
        duration: CARD_EXIT_DURATION,
        easing: 'ease-out',
        fill: 'forwards',
      }
    );
    animation.addEventListener('finish', onTransitionExit);
    // animation.commitStyles();
    oldAnimationRef.current = animation;
  }, [transitionProps.in]);
}
