import { Game, PlayerGamePartial, GameStatus } from '../types/Game';
import {
  mapValues,
  areAll,
  flattenRecords,
  isNotNull,
  shuffle,
  filterValues,
  xor,
} from './general';
import {
  createOwnPlayer,
  createPlayerSummary,
  isActive as isPlayerActive,
} from './player';
import { Team } from '../types/Team';
import { Room } from '../types/Room';
import { Dict } from '../types/General';
import { getAllUserIdsInRoom } from './room';
import { PlayerStatus } from '../types/Player';
import { CardValue, TotalNumberOfRegularCardValues } from '../types/Card';
import { getTeamsInOrder } from './team';

export function getPlayerGamePartial(
  { players, deck, ...gamePublicProperties }: Game,
  ownUserId: string
): PlayerGamePartial {
  return {
    ...gamePublicProperties,
    ownPlayer: createOwnPlayer(players[ownUserId]),
    playerSummaries: mapValues(players, createPlayerSummary),
    deckLength: deck.length,
  };
}

export function dealInitialCards(room: Room, initialDeck: number[]) {
  const userIds = getAllUserIdsInRoom(room);
  const countOfPlayers = userIds.length;
  const countOfCardsToBeDealt = room.startingHandCount * countOfPlayers;
  const hands = userIds.reduce(
    (handsDraft, userId, index) => ({
      ...handsDraft,
      [userId]: initialDeck.slice(
        index * room.startingHandCount,
        (index + 1) * room.startingHandCount
      ),
    }),
    {} as Dict<number[]>
  );
  const discard = [initialDeck[countOfCardsToBeDealt]];
  const deck = initialDeck.slice(countOfCardsToBeDealt + 1, initialDeck.length);
  return { deck, discard, hands };
}

export function shuffleDiscardIntoDeck(
  deck: CardValue[],
  discard: CardValue[]
) {
  if (deck.length === 0 && discard.length > 1) {
    const [discardTopCard, ...cardsToShuffle] = discard;
    return { deck: shuffle(cardsToShuffle), discard: [discardTopCard] };
  }
  return { deck, discard };
}

export function getGameStatusFromPlayerStatus(
  playerStatus: Dict<PlayerStatus>
): GameStatus {
  return areAll(
    flattenRecords(playerStatus),
    (playerStatus) => !isPlayerActive(playerStatus)
  )
    ? GameStatus.Complete
    : GameStatus.Active;
}

export function getGameStatus(game: Game | PlayerGamePartial): GameStatus {
  const players = 'players' in game ? game.players : game.playerSummaries;
  return getGameStatusFromPlayerStatus(
    mapValues(players, (player) => player.status)
  );
}

export function getUserIdOrderFromTeams(
  game: Game | PlayerGamePartial
): string[] {
  const order = [];
  const teams = getTeamsInOrder(game.teams, game.teamOrder);
  // Return in order of 1st in each team, then 2nd in each team
  const teamCount = teams.length;
  const teamSize = 2;
  for (let positionInTeam = 0; positionInTeam < teamSize; positionInTeam++) {
    for (let teamIndex = 0; teamIndex < teamCount; teamIndex++) {
      order.push(teams[teamIndex].userIds[positionInTeam]);
    }
  }
  return order.filter(isNotNull);
}

export function canEndTurn(playerId: string, game: PlayerGamePartial) {
  return (
    game.status === GameStatus.Active &&
    game.currentTurn.currentPlayerId === playerId &&
    game.currentTurn.canFinish
  );
}

export function getAllUserIdsInGame(game: Game | PlayerGamePartial): string[] {
  return Object.values(game.teams)
    .flatMap((team) => team.userIds)
    .filter(isNotNull);
}

export function isUserIdInGame(
  game: Game | PlayerGamePartial,
  targetUserId: string
) {
  return getAllUserIdsInGame(game).includes(targetUserId);
}

export function isUsersTurn(
  game: Game | PlayerGamePartial,
  targetUserId: string
) {
  return game.currentTurn.currentPlayerId === targetUserId;
}

export function getPreviousPlayer(game: Game) {
  return getConsecutivePlayer(game, 1, true);
}

export function getNextPlayer(game: Game) {
  return getConsecutivePlayer(game, 1, false);
}

export function getNextPlayerToPlay(game: Game) {
  const playersToMoveForward = game.currentTurn.willSkipNextPlayer ? 2 : 1;
  return getConsecutivePlayer(game, playersToMoveForward, false);
}

function getConsecutivePlayer(
  game: Game,
  playersToMoveForward: number,
  isPrevious: boolean
) {
  let order = getUserIdOrderFromTeams(game);
  if (xor(!game.isDirectionForward, isPrevious)) {
    order = order.reverse();
  }
  let nextPlayerIndex = order.indexOf(game.currentTurn.currentPlayerId);
  while (playersToMoveForward > 0) {
    nextPlayerIndex = (nextPlayerIndex + 1) % order.length;
    const proposedNextPlayerUserId = order[nextPlayerIndex];
    const proposedNextPlayer = game.players[proposedNextPlayerUserId];
    if (proposedNextPlayer.status === PlayerStatus.Active) {
      playersToMoveForward--;
    }
  }
  return order[nextPlayerIndex];
}

export function isValidCardInGame(card: CardValue | undefined, game: Game) {
  return (
    card &&
    card.id >= 0 &&
    card.id < TotalNumberOfRegularCardValues + game.jokerCount
  );
}

export function getPlayerStatuses(
  game: Game,
  hands: Dict<CardValue[]>
): Dict<PlayerStatus> {
  const teams = getTeamsInOrder(game.teams, game.teamOrder);
  // Check if any team has no more collective cards, victory!
  // Check if any team has a collective Joker, defeat!
  let hasWinnerByNoCardsLeft = false;
  let survivingTeamCount = 0;
  const teamStatus: {
    [teamId: string]: 'undecided' | 'won' | 'disqualified' | 'eliminated';
  } = {};
  // One pass over the team members to count cards
  for (let team of teams) {
    let cardCount = 0;
    let jokerCount = 0;
    let activePlayers = 0;
    for (let playerUserId of team.userIds) {
      if (
        playerUserId &&
        game.players[playerUserId].status === PlayerStatus.Active
      ) {
        const playerHand = hands[playerUserId];
        cardCount += playerHand?.length || 0;
        jokerCount += playerHand.filter((card) => card.isSpecial)?.length || 0;
        activePlayers++;
      }
    }
    if (activePlayers === 0) {
      teamStatus[team.id] = 'disqualified';
    } else if (jokerCount > 0) {
      teamStatus[team.id] = 'eliminated';
    } else if (cardCount === 0) {
      teamStatus[team.id] = 'won';
      survivingTeamCount++;
      hasWinnerByNoCardsLeft = true;
    } else {
      teamStatus[team.id] = 'undecided';
      survivingTeamCount++;
    }
  }
  const hasGameCompleted = hasWinnerByNoCardsLeft || survivingTeamCount === 1;
  // Second pass over the team members to set statuses, by playerId (not userId)
  const playerStatuses: Dict<PlayerStatus | null> = {};
  for (let team of teams) {
    const isWinningTeam =
      teamStatus[team.id] === 'won' ||
      (survivingTeamCount === 1 && teamStatus[team.id] === 'undecided');
    const isLosingTeam = hasGameCompleted && !isWinningTeam;
    const isEliminated =
      !hasGameCompleted && teamStatus[team.id] === 'eliminated';
    for (let playerUserId of team.userIds) {
      if (playerUserId) {
        playerStatuses[game.players[playerUserId].id] = isWinningTeam
          ? PlayerStatus.Won
          : isLosingTeam
          ? PlayerStatus.Lost
          : isEliminated
          ? PlayerStatus.Eliminated
          : game.players[playerUserId].status;
      }
    }
  }
  return filterValues(playerStatuses, isNotNull);
}

export function getSinglePlayerTeams(teams: Team[]) {
  // TODO
}

export function getTopDiscardValue(game: Game | PlayerGamePartial) {
  if (game.discardTopOverride) return game.discardTopOverride;
  return game.discard[0];
}
