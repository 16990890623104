import React, { useCallback, useState } from 'react';
import './GamePage.css';
import PlayerHand, { CollapseLevel } from '../../ui/PlayerHand/PlayerHand';
import PlayerArea from '../../ui/PlayerArea/PlayerArea';
import DirectionArrow from '../../ui/DirectionArrow/DirectionArrow';
import {
  createVisibleCard,
  sortCards,
  isCardPlayable,
  isCardPlayableInRun,
  isCardOfRank,
  createRegularCardValue,
  getSuitName,
  isCardOfSuit,
} from '../../lib/card';
import { createRange, excludeKey, rotateToStartWith } from '../../lib/general';
import { PlayerStatus, PlayerSummary } from '../../types/Player';
import { Dict } from '../../types/General';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../hooks/store/user';
import {
  useDrawCard,
  useEndTurn,
  useGame,
  useLeaveGame,
  usePlayCard,
  useSubscribeGame,
} from '../../hooks/store/game';
import {
  canEndTurn,
  getTopDiscardValue,
  getUserIdOrderFromTeams,
  isUsersTurn,
} from '../../lib/game';
import { useOwnUser } from '../../hooks/store/authentication';
import { PlayerGamePartial } from '../../types/Game';
import { getRoomPagePath } from '../../lib/routing';
import { CardRank, CardSuit, CardValue } from '../../types/Card';
import { Modal } from '../../ui/Modal/Modal';
import PlayingCardLabel from '../../ui/PlayingCardLabel/PlayingCardLabel';
import { useBoundRef } from '../../hooks/useBoundRef';
import { Discard } from '../../ui/Discard/Discard';
import { Deck } from '../../ui/Deck/Deck';

function GamePage() {
  const [suitSelectionModalState, setSuitSelectionModalState] = useState<
    { isOpen: false } | { isOpen: true; selectedCardValue: CardValue }
  >({ isOpen: false });
  const navigate = useNavigate();
  const { roomId, gameId } = useParams<{ gameId: string; roomId: string }>();
  const ownUser = useOwnUser();
  const drawCard = useDrawCard();
  const playCard = usePlayCard();
  const endTurn = useEndTurn();
  const leaveGame = useLeaveGame();
  const game = useGame(gameId);
  // Subscribe to game updates
  useSubscribeGame(gameId);
  const handleLeaveGame = useCallback(
    async (event) => {
      event.preventDefault();
      if (!roomId || !gameId) return;
      await leaveGame(roomId, gameId);
      navigate(getRoomPagePath(roomId));
    },
    [leaveGame, navigate]
  );
  if (!game || !ownUser || !roomId || !gameId) return null;
  const isStartOfTurn = !game.currentTurn.canFinish;
  const ownPlayer = game.ownPlayer;
  const { isDirectionForward, playerSummaries } = game;
  const playerOrder = getUserIdOrderFromTeams(game);
  const otherPlayerOrder = rotateToStartWith(playerOrder, ownUser.id).filter(
    (userId) => userId !== ownUser.id
  );
  const otherPlayers = excludeKey(playerSummaries, ownUser.id);
  const topDiscardValue = getTopDiscardValue(game);
  const sortedOwnPlayerHand = sortCards(ownPlayer.hand);
  const isOwnUsersTurn = isUsersTurn(game, ownUser.id);
  const handlePlayCard = (
    cardValue: CardValue,
    overriddenCardValue: CardValue | null
  ) => {
    // It is the player's current turn and they did not just play an ace as a wild card
    const playerCanPlay =
      isOwnUsersTurn && (isStartOfTurn || game.discardTopOverride === null);
    const cardCanBePlayed = isStartOfTurn
      ? isCardPlayable(cardValue, topDiscardValue)
      : isCardPlayableInRun(cardValue, topDiscardValue);
    if (playerCanPlay && cardCanBePlayed) {
      if (
        isStartOfTurn &&
        isCardOfRank(cardValue, CardRank.Ace) &&
        !suitSelectionModalState.isOpen
      ) {
        setSuitSelectionModalState({
          isOpen: true,
          selectedCardValue: cardValue,
        });
        return;
      }
      playCard(roomId, gameId, cardValue, overriddenCardValue);
      setSuitSelectionModalState({ isOpen: false });
    }
  };
  const handleCloseSuitSelectionModal = () => {
    setSuitSelectionModalState({ isOpen: false });
  };
  return (
    <div className="gamePage">
      <div className="gamePage__top">
        <OtherPlayersSection
          order={otherPlayerOrder}
          otherPlayers={otherPlayers}
          game={game}
          topDiscardValue={topDiscardValue}
        />
      </div>
      <div className="gamePage__center">
        <div className="gamePage__direction gamePage__direction--clockwise">
          <DirectionArrow hidden={!isDirectionForward} />
          <button
            onClick={() => endTurn(roomId, gameId)}
            disabled={!canEndTurn(ownUser.id, game)}
            style={{ visibility: !isDirectionForward ? 'hidden' : 'visible' }}
          >
            End Turn
          </button>
        </div>
        <div className="gamePage__deck" id="gamePage__deck">
          <Deck
            game={game}
            gameId={gameId}
            roomId={roomId}
            onClick={drawCard}
            isOwnUsersTurn={isOwnUsersTurn}
          />
        </div>
        <div className="gamePage__discard" id="gamePage__discard">
          <Discard topDiscardValue={topDiscardValue} />
        </div>
        <div className="gamePage__direction gamePage__direction--counterClockwise">
          <DirectionArrow flipHorizontal={true} hidden={isDirectionForward} />
          <button
            onClick={() => endTurn(roomId, gameId)}
            disabled={!canEndTurn(ownUser.id, game)}
            style={{ visibility: isDirectionForward ? 'hidden' : 'visible' }}
          >
            End Turn
          </button>
        </div>
      </div>
      <div className="gamePage__bottom">
        {ownUser && (
          <PlayerArea
            name={ownUser.name}
            isThisUsersTurn={isOwnUsersTurn}
            status={game.ownPlayer.status}
          >
            <PlayerHand
              cards={sortedOwnPlayerHand.map(createVisibleCard)}
              onSelectCard={(cardValue) => handlePlayCard(cardValue, null)}
              collapseLevel={
                sortedOwnPlayerHand.length > 8
                  ? CollapseLevel.Collapsed
                  : CollapseLevel.Expanded
              }
              isOwnUser={true}
            />
          </PlayerArea>
        )}
      </div>
      {ownPlayer.status !== PlayerStatus.Active && (
        <div>
          <Link to={getRoomPagePath(roomId)}>Return to room</Link>
        </div>
      )}
      {ownPlayer.status === PlayerStatus.Active && (
        <div>
          <button onClick={handleLeaveGame}>Quit game</button>
        </div>
      )}
      {suitSelectionModalState.isOpen && (
        <SuitSelectionModal
          selectedCardValue={suitSelectionModalState.selectedCardValue}
          onPlayCard={handlePlayCard}
          onClose={handleCloseSuitSelectionModal}
        />
      )}
    </div>
  );
}

function OtherPlayersSection({
  order,
  otherPlayers,
  game,
  topDiscardValue,
}: {
  order: string[];
  otherPlayers: Dict<PlayerSummary>;
  game: PlayerGamePartial;
  topDiscardValue: CardValue;
}) {
  return (
    <div className="otherPlayersSection">
      {order.map((playerUserId) => (
        <OtherPlayerSection
          key={playerUserId}
          topDiscardValue={topDiscardValue}
          playerSummary={otherPlayers[playerUserId]}
          isThisUsersTurn={isUsersTurn(game, playerUserId)}
        />
      ))}
    </div>
  );
}

function OtherPlayerSection({
  playerSummary,
  isThisUsersTurn,
  topDiscardValue,
}: {
  playerSummary: PlayerSummary;
  isThisUsersTurn: boolean;
  topDiscardValue: CardValue;
}) {
  const topDiscardValueRef = useBoundRef(topDiscardValue);
  const user = useUser(playerSummary.userId);
  if (!user) return null;
  // Create hand of hidden cards with the top discard card last. This will make the animation
  // when the player plays a card show the new discard card.
  const cards = createRange(0, playerSummary.handCount).map(() =>
    createVisibleCard(topDiscardValue)
  );
  return (
    <div className="otherPlayersSection__player" key={playerSummary.userId}>
      <PlayerArea
        name={user.name}
        isThisUsersTurn={isThisUsersTurn}
        status={playerSummary.status}
      >
        <PlayerHand
          cards={cards}
          collapseLevel={
            playerSummary.handCount > 8
              ? CollapseLevel.Condensed
              : CollapseLevel.Collapsed
          }
          isOwnUser={false}
          overrideCardValueRef={topDiscardValueRef}
        />
      </PlayerArea>
    </div>
  );
}

function SuitSelectionModal({
  selectedCardValue,
  onPlayCard,
  onClose,
}: {
  selectedCardValue: CardValue;
  onPlayCard: (
    selectedCardValue: CardValue,
    overriddenCardValue: CardValue | null
  ) => void;
  onClose: () => void;
}) {
  return (
    <Modal onClose={onClose}>
      <h2>Choose a suit</h2>
      <SuitSelectionButton
        cardSuit={CardSuit.Hearts}
        selectedCardValue={selectedCardValue}
        onPlayCard={onPlayCard}
      />
      <SuitSelectionButton
        cardSuit={CardSuit.Diamonds}
        selectedCardValue={selectedCardValue}
        onPlayCard={onPlayCard}
      />
      <SuitSelectionButton
        cardSuit={CardSuit.Clubs}
        selectedCardValue={selectedCardValue}
        onPlayCard={onPlayCard}
      />
      <SuitSelectionButton
        cardSuit={CardSuit.Spades}
        selectedCardValue={selectedCardValue}
        onPlayCard={onPlayCard}
      />
    </Modal>
  );
}

function SuitSelectionButton({
  cardSuit,
  onPlayCard,
  selectedCardValue,
}: {
  cardSuit: CardSuit;
  onPlayCard: (
    selectedCardValue: CardValue,
    overriddenCardValue: CardValue | null
  ) => void;
  selectedCardValue: CardValue;
}) {
  const newCardValue = createRegularCardValue(-3, CardRank.Ace, cardSuit);
  const isSameSuitAsSelected = isCardOfSuit(selectedCardValue, cardSuit);
  return (
    <div>
      <button
        onClick={() =>
          onPlayCard(
            selectedCardValue,
            isSameSuitAsSelected ? null : newCardValue
          )
        }
        aria-label={getSuitName(newCardValue)}
      >
        <PlayingCardLabel value={newCardValue} />
      </button>
      {isSameSuitAsSelected && (
        <span>
          (Keep as is and you will be able to continue a run off this card)
        </span>
      )}
    </div>
  );
}

export default GamePage;
