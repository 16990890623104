import React from 'react';
import './EditableText.css';

interface EditableTextProps {
  value: string;
  onChange: (newValue: string) => void;
  onCommit?: (newValue: string) => void;
  disabled?: boolean;
}

export default function EditableText({
  value,
  onChange,
  onCommit,
  disabled = false,
}: EditableTextProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  const handleCommit = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onCommit) {
      onCommit(event.target.value);
    }
  };
  return (
    <input
      className="editableText"
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleCommit}
      disabled={disabled}
    />
  );
}
