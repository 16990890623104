import React, { useEffect, useState, MouseEvent, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useOwnUser } from '../../hooks/store/authentication';
import { useCreateGame } from '../../hooks/store/game';
import {
  useRoom,
  useSubscribeRoom,
  useUpdateRoom,
} from '../../hooks/store/room';
import {
  useCreateTeam,
  useRemoveTeam,
  useUpdateTeamName,
  useUpdateTeamUserIds,
} from '../../hooks/store/team';
import { useUser } from '../../hooks/store/user';
import { useCountdown } from '../../hooks/useCountdown';
import {
  canAddTeamToRoom,
  getAllUserIdsInRoom,
  hasAllValidTeams,
} from '../../lib/room';
import { getGamePagePath, getHomePagePath } from '../../lib/routing';
import {
  canJoinTeam,
  canLeaveTeam,
  canRemoveTeam,
  canSetTeamName,
  getTeamIdForUser,
  getTeamOpeningIndex,
  getTeamsInOrder,
  getUserIndexInTeam,
} from '../../lib/team';
import { Dict } from '../../types/General';
import { Team } from '../../types/Team';
import ClickToCopy from '../../ui/Forms/ClickToCopy';
import EditableText from '../../ui/Forms/EditableText';
import GameOptionsForm from './GameOptionsForm';

function RoomPage() {
  const { roomId } = useParams<{ roomId: string }>();
  const navigate = useNavigate();
  const ownUser = useOwnUser();
  const updateRoom = useUpdateRoom();
  const createTeam = useCreateTeam();
  const updateTeamUserIds = useUpdateTeamUserIds();
  const createGame = useCreateGame();
  const room = useRoom(roomId);
  const [roomNameDraft, setRoomNameDraft] = useState(room?.name);
  const [isGameBeingCreated, setIsGameBeingCreated] = useState(false);
  const [timeToEnterRoom, startTimeToEnterRoom, cancelTimeToEnterRoom] =
    useCountdown();
  // Change the room name with server updates
  useEffect(() => {
    setRoomNameDraft(room?.name);
  }, [room?.name]);
  // Remove user from the room when closing the tab
  // TODO: this needs fixing
  // useEffect(() => {
  //   document.addEventListener('DOMContentLoaded', () => {
  //     document.addEventListener('pagehide', handleLeaveRoom);
  //   });
  //   return () => {
  //     document.removeEventListener('pagehide', handleLeaveRoom);
  //   };
  // }, []);
  // Subscribe to room updates
  useSubscribeRoom(roomId);
  // Watch for changes in activeGameId
  useEffect(() => {
    if (room?.activeGameId) {
      startTimeToEnterRoom(5, () => {
        if (room?.activeGameId) {
          navigate(getGamePagePath(room.id, room.activeGameId));
        }
      });
    }
    return cancelTimeToEnterRoom;
  }, [room?.activeGameId]);
  if (!room || !ownUser || !roomId) return null;
  const userIds = getAllUserIdsInRoom(room);
  const ownUserId = ownUser.id;
  const ownTeamId = getTeamIdForUser(ownUserId, room.teams);
  const teamList = getTeamsInOrder(room.teams, room.teamOrder);
  // TODO: Generate this link from the URL in the window object
  const roomLink = `https://survival-game.app/room/${roomId}`;
  const canStartGame = hasAllValidTeams(room) && room.activeGameId === null;
  const handleCreateNewTeam = () => {
    if (!canAddTeamToRoom(room)) return;
    createTeam(room.id);
  };
  const handleLeaveRoomClick = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    await handleLeaveRoom();
    navigate(getHomePagePath());
  };
  const handleLeaveRoom = async () => {
    if (!ownTeamId) return;
    const userIdIndex = getUserIndexInTeam(room.teams[ownTeamId], ownUserId);
    if (!canLeaveTeam(ownUserId, room.teams[ownTeamId]) || userIdIndex === null)
      return;
    await updateTeamUserIds(
      roomId,
      ownTeamId,
      userIdIndex,
      { userId: null },
      { shouldKeepAlive: true } // Keep connection alive even if tab closed
    );
  };
  const handleStartGame = async () => {
    setIsGameBeingCreated(true);
    // Create game
    const game = await createGame(room.id);
    // Navigate to new game
    navigate(getGamePagePath(room.id, game.id));
  };
  return (
    <div>
      <h1>
        Room:{' '}
        <EditableText
          value={roomNameDraft || ''}
          onChange={(name) => setRoomNameDraft(name)}
          onCommit={(name) => updateRoom(roomId, { name })}
        />{' '}
        {room.isPrivate && <span>(Private)</span>}
      </h1>
      <p>
        Invite other players with this link:{' '}
        <ClickToCopy>{roomLink}</ClickToCopy>
      </p>
      <h2>{room.hasTeams ? 'Teams' : 'Players'}</h2>
      {room.hasTeams ? (
        <>
          {teamList.map((team) => (
            <TeamListing
              key={team.id}
              team={team}
              hostUserId={room.hostUserId}
              ownUserId={ownUserId}
              roomId={roomId}
              scores={room.scores}
            />
          ))}
          {canAddTeamToRoom(room) && (
            <button onClick={handleCreateNewTeam}>Create Team</button>
          )}
        </>
      ) : (
        userIds.map(
          (userId) =>
            userId && (
              <UserListing
                key={userId}
                userId={userId}
                hostUserId={room.hostUserId}
                scores={room.scores}
              />
            )
        )
      )}
      <GameOptionsForm room={room} />
      <p>
        {room.activeGameId !== null ? (
          <Link to={getGamePagePath(room.id, room.activeGameId)}>
            Join current game
          </Link>
        ) : (
          <>
            <button
              disabled={!canStartGame || isGameBeingCreated}
              onClick={handleStartGame}
            >
              Start Game
            </button>
            {!canStartGame && (
              <>
                {room.hasTeams ? (
                  <span>There must be 2 or more teams of 2 players each</span>
                ) : (
                  <span>There must be 2 or more players</span>
                )}
              </>
            )}
          </>
        )}
        {timeToEnterRoom !== null && (
          <span>(Joining in {timeToEnterRoom})</span>
        )}
      </p>
      <p>
        <Link to={getHomePagePath()} onClick={handleLeaveRoomClick}>
          Leave Room
        </Link>
      </p>
    </div>
  );
}

interface TeamListingProps {
  team: Team;
  hostUserId: string;
  ownUserId: string;
  roomId: string;
  scores: Dict<number>;
}

function TeamListing({
  team,
  hostUserId,
  ownUserId,
  roomId,
  scores,
}: TeamListingProps) {
  const [teamNameDraft, setTeamNameDraft] = useState(team?.name);
  const updateTeamName = useUpdateTeamName();
  const updateTeamUserIds = useUpdateTeamUserIds();
  const removeTeam = useRemoveTeam();
  // Change the team name with server updates
  useEffect(() => {
    setTeamNameDraft(team?.name);
  }, [team?.name]);
  return (
    <div key={team.id}>
      <p>
        <strong>
          <EditableText
            value={teamNameDraft}
            onChange={setTeamNameDraft}
            onCommit={(name: string) =>
              updateTeamName(roomId, team.id, { name })
            }
            disabled={!canSetTeamName(team, ownUserId)}
          />
        </strong>{' '}
        {canJoinTeam(ownUserId, team) && (
          <button
            onClick={() => {
              const openingIndex = getTeamOpeningIndex(team);
              if (openingIndex === null) return;
              updateTeamUserIds(roomId, team.id, openingIndex, {
                userId: ownUserId,
              });
            }}
          >
            Join
          </button>
        )}
        {canRemoveTeam(team) && (
          <button onClick={() => removeTeam(roomId, team.id)}>Remove</button>
        )}
      </p>
      {team.userIds.map(
        (userId) =>
          userId && (
            <UserListing
              key={userId}
              userId={userId}
              hostUserId={hostUserId}
              scores={scores}
            />
          )
      )}
    </div>
  );
}

interface UserListingProps {
  userId: string;
  hostUserId: string;
  scores: Dict<number>;
}

function UserListing({ userId, hostUserId, scores }: UserListingProps) {
  const ownUser = useOwnUser();
  const user = useUser(userId);
  if (!user) return null;
  const isHost = userId === hostUserId;
  const isOwnUser = userId === ownUser?.id;
  return (
    <p>
      <span>{user.name}</span> <span>({scores[userId] || 0} wins)</span>{' '}
      {isOwnUser && <span>(You)</span>} {isHost && <span>(Host)</span>}
    </p>
  );
}

export default RoomPage;
