import React from 'react';
import './CheckBox.css';

interface CheckBoxProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export default function CheckBox({ value, onChange }: CheckBoxProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };
  return (
    <input
      className="checkBox"
      type="checkbox"
      checked={value}
      onChange={handleChange}
    />
  );
}
