import React, { useCallback, useState, MouseEvent } from 'react';
import { Room } from '../../types/Room';
import { Link, useNavigate } from 'react-router-dom';
import { useFetchRoom, useRooms } from '../../hooks/store/room';
import EditableText from '../../ui/Forms/EditableText';
import { canAddTeamToRoom, getAllUserIdsInRoom } from '../../lib/room';
import { useOwnUser } from '../../hooks/store/authentication';
import { useSetUserName } from '../../hooks/store/user';
import { OwnUser } from '../../types/User';
import CreateRoomModal from '../../ui/CreateRoomModal/CreateRoomModal';
import {
  getIdForFirstAvailableTeam,
  getTeamOpeningIndex,
  isUserInSomeTeam,
} from '../../lib/team';
import { useCreateTeam, useUpdateTeamUserIds } from '../../hooks/store/team';
import { useSubscribeHome } from '../../hooks/store/home';
import { getRoomPagePath } from '../../lib/routing';
import { useCountdown } from '../../hooks/useCountdown';

function HomePage() {
  const rooms = useRooms();
  const ownUser = useOwnUser();
  useSubscribeHome();
  return (
    <div>
      <h1>Survival</h1>
      {ownUser && <WelcomeMessage ownUser={ownUser} />}
      <h2>Game Rooms</h2>
      <div>
        {rooms?.map((room) => (
          <RoomListing key={room.id} room={room} />
        ))}
      </div>
      <p>
        <Link to="#create-room">Create Room</Link>
      </p>
      <CreateRoomModal />
    </div>
  );
}

interface RoomListingProps {
  room: Room;
}

function RoomListing({ room }: RoomListingProps) {
  const ownUser = useOwnUser();
  const createTeam = useCreateTeam();
  const updateTeamUserIds = useUpdateTeamUserIds();
  const fetchRoom = useFetchRoom();
  const navigate = useNavigate();
  const roomUrl = getRoomPagePath(room.id);
  const roomCount = getAllUserIdsInRoom(room).length;
  const [remainingErrorTimeInSeconds, startErrorCountdown] = useCountdown();
  const handleJoinRoom = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      async function tryAddUserToRoom(options: {
        shouldFetchRoom: boolean;
      }): Promise<boolean> {
        let updatedRoom = room;

        async function retryAddUserToRoom() {
          // Only retry if we aren't trying for the first time now
          return options.shouldFetchRoom
            ? false
            : tryAddUserToRoom({ shouldFetchRoom: true });
        }

        // Try loading a fresh copy of the room data
        if (options.shouldFetchRoom) {
          updatedRoom = await fetchRoom(updatedRoom.id);
        }
        // If these are undefined, bail
        if (!updatedRoom || !ownUser) return false;
        // If user is already in a team, we're already gtg
        if (isUserInSomeTeam(ownUser.id, updatedRoom.teams)) return true;
        let teamIdToJoin = getIdForFirstAvailableTeam(
          ownUser.id,
          updatedRoom.teams
        );
        if (!teamIdToJoin) {
          // If there's no team available, create a new one
          if (!canAddTeamToRoom(updatedRoom)) return false;
          updatedRoom = await createTeam(updatedRoom.id);
          teamIdToJoin = getIdForFirstAvailableTeam(
            ownUser.id,
            updatedRoom.teams
          );
        }
        if (!teamIdToJoin) return retryAddUserToRoom();
        // Find the index to add the user into
        const openingIndex = getTeamOpeningIndex(
          updatedRoom.teams[teamIdToJoin]
        );
        if (openingIndex === null) return retryAddUserToRoom();
        // Update the room
        try {
          updatedRoom = await updateTeamUserIds(
            updatedRoom.id,
            teamIdToJoin,
            openingIndex,
            {
              userId: ownUser.id,
            }
          );
          if (!updatedRoom) return retryAddUserToRoom();
        } catch (error) {
          return retryAddUserToRoom();
        }
        // Success!
        return true;
      }

      const isInTeam = await tryAddUserToRoom({ shouldFetchRoom: false });

      if (isInTeam) {
        navigate(roomUrl);
      } else {
        startErrorCountdown(3);
      }
    },
    [
      room,
      ownUser,
      roomUrl,
      createTeam,
      navigate,
      updateTeamUserIds,
      fetchRoom,
      startErrorCountdown,
    ]
  );
  return (
    <div>
      <span>{room.name}</span> <span>({roomCount})</span>{' '}
      <Link onClick={handleJoinRoom} to={roomUrl}>
        Join
      </Link>
      {remainingErrorTimeInSeconds !== null && (
        <span>Unable to join this room.</span>
      )}
    </div>
  );
}

interface WelcomeMessageProps {
  ownUser: OwnUser;
}

function WelcomeMessage({ ownUser }: WelcomeMessageProps) {
  const [userNameDraft, setUserNameDraft] = useState(ownUser.name);
  const setUserName = useSetUserName();
  return (
    <div>
      <p>
        Welcome{' '}
        <EditableText
          value={userNameDraft || ''}
          onChange={(newName) => setUserNameDraft(newName)}
          onCommit={(newName) => setUserName(ownUser?.id, newName)}
        />
      </p>
      <p>Total Wins: {ownUser.lifetimeWins}</p>
      <p>Total Games: {ownUser.lifetimeGamesPlayed}</p>
    </div>
  );
}

export default HomePage;
