import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useQueryParam(key: string) {
  const query = useQuery();
  return query.get(key);
}

export function useHash() {
  return useLocation().hash;
}

export function getHomePagePath() {
  return '/';
}

export function getRoomPagePath(roomId: string) {
  return `/room/${roomId}`;
}

export function getGamePagePath(roomId: string, gameId: string) {
  return `/room/${roomId}/game/${gameId}`;
}
