import { useEffect } from 'react';
import { subscribe } from '../../api-client/core';
import { Room } from '../../types/Room';
import { useRoomStore } from './room';

export function subscribeHome(callback: (response: { rooms: Room[] }) => void) {
  return subscribe<{ rooms: Room[] }>({
    endpoint: '/subscribe/home',
    callback,
  });
}

export function useSubscribeHome() {
  const { setRoom } = useRoomStore();
  useEffect(() => {
    const unsubscribeHome = subscribeHome(({ rooms }) => {
      rooms.forEach((room) => {
        setRoom(room.id, () => room);
      });
    });
    return unsubscribeHome;
  }, []);
}
